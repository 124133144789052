import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import PersonIcon from "@mui/icons-material/Person";
import useDataUsr from "../../hooks/useDataUsr";

const ReadTickets = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    if (dataUsr) {
      setCargando(false);
    }
  }, [dataUsr]);
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Tickets Soporte IT</Typography>
          </Breadcrumbs>
          {dataUsr && dataUsr.permisos != "ADMINISTRADOR" && (
            <Button
              variant="contained"
              tabIndex={-1}
              startIcon={<PersonIcon />}
              href={`/ticket-soporte/create`}
              sx={{ width: { xs: "100%", md: "100%", lg: "35%" } }}
            >
              Registrar Ticket
            </Button>
          )}
          {dataUsr && (
            <Read
              config={{
                title: "Tickets registrados",
                endpoint:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? `/ticket-soporte`
                    : `/ticket-soporte/read-usr/${dataUsr.sub}`,
                id: "id",
                onDelete:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? "/ticket-soporte"
                    : null,
                onView: `/ticket-soporte/view`,
                onUpdate:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? `/ticket-soporte/update`
                    : null,
                admin: true,
                headers:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? [
                        {
                          id: "fecha_registro",
                          numeric: false,
                          disablePadding: true,
                          label: "Fecha de Solicitud",
                        },
                        {
                          id: "asunto",
                          numeric: false,
                          disablePadding: true,
                          label: "Asunto Ticket",
                        },
                        {
                          id: "estado",
                          numeric: false,
                          disablePadding: true,
                          label: "Estado de Solicitud",
                        },
                        {
                          id: "categoria",
                          numeric: false,
                          disablePadding: true,
                          label: "Categoría",
                        },
                        {
                          id: "prioridad",
                          numeric: false,
                          disablePadding: true,
                          label: "Prioridad",
                        },
                      ]
                    : [
                        {
                          id: "fecha_registro",
                          numeric: false,
                          disablePadding: true,
                          label: "Fecha de Solicitud",
                        },
                        {
                          id: "asunto",
                          numeric: false,
                          disablePadding: true,
                          label: "Asunto Ticket",
                        },

                        {
                          id: "estado",
                          numeric: false,
                          disablePadding: true,
                          label: "Estado de Solicitud",
                        },
                      ],
              }}
            />
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadTickets;
