import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
import useDataUsr from "../../hooks/useDataUsr";
import { useParams } from "react-router-dom";
const admin_service = require("../../helpers/admin_service");
const UpdateInventarioTI = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState(null);
  const { id } = useParams();
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/inventario-ti/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setFormConfig({
            title: `Actualizar información de equipo`,
            submitTitle: "Guardar información",
            formConfig: [
              {
                type: 10,
                required: true,
                value: datos.data.response_database.result[0].descripcion,
                name: "descripcion",
                label: "Ingrese la descripción del equipo",
                title: "Descripción de equipo",
              },
              {
                type: 1,
                required: true,
                value: datos.data.response_database.result[0].serial,
                name: "serial",
                label: "Ingrese el código serial del equipo",
                title: "Código serial de equipo",
              },
              {
                type: 5,
                required: true,
                value:
                  datos.data.response_database.result[0].vencimiento_garantia,
                name: "vencimiento_garantia",
                label: "Fecha de vencimiento de garantía de equipo",
                title: "Fecha de vencimiento de garantía de equipo",
              },
            ],
          });
          setCargando(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .putDataAuth(
          "/inventario-ti/" + id,
          jwt.TokenType,
          jwt.accessToken,
          body
        )
        .then((response_database) => {
          window.location.href = `/inventario-ti/view/${id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del equipo, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/inventario-ti"}>
              inventario IT
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={"/inventario-ti/view/" + id}
            >
              {id}
            </Link>
            <Typography color="text.primary">Actualizar Equipo</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UpdateInventarioTI;
