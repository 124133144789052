import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Button,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import PersonIcon from "@mui/icons-material/Person";
import useDataUsr from "../../hooks/useDataUsr";
import ComputerIcon from "@mui/icons-material/Computer";

const ReadInventarioTI = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    if (dataUsr) {
      setCargando(false);
    }
  }, [dataUsr]);
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Inventario IT</Typography>
          </Breadcrumbs>

          <Button
            variant="contained"
            tabIndex={-1}
            startIcon={<ComputerIcon />}
            href={`/inventario-ti/create`}
            sx={{ width: { xs: "100%", md: "100%", lg: "35%" } }}
          >
            Registrar Equipo
          </Button>
          {dataUsr && (
            <Read
              config={{
                title: "Inventario IT",
                endpoint: `/inventario-ti`,
                id: "id",
                onDelete: "/inventario-ti",
                onView: `/inventario-ti/view`,
                onUpdate: `/inventario-ti/update`,
                admin: true,
                headers: [
                  {
                    id: "fecha_registro",
                    numeric: false,
                    disablePadding: true,
                    label: "Fecha de Solicitud",
                  },
                  {
                    id: "serial",
                    numeric: false,
                    disablePadding: true,
                    label: "Serial",
                  },
                  {
                    id: "vencimiento_garantia",
                    numeric: false,
                    disablePadding: true,
                    label: "Vencimiento Garantía",
                  },
                ],
              }}
            />
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadInventarioTI;
