import React, { useEffect, useState } from "react";
import {
  Backdrop,
  CircularProgress,
  Grid,
  Card,
  CardContent,
  CardMedia,
  Typography,
  Stack,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from "js-cookie";
import ActionBar from "./ActionBar";
import Attributes from "./Attributes";
const admin_service = require("../../helpers/admin_service");
const auth_service = require("../../helpers/authService");

const View = (props) => {
  const [contador] = useState(0);
  const [view, setView] = useState({});
  const [loading, setLoading] = useState(true);
  useEffect(() => {
    if (props.configuration != null) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      if (props.configuration.auth) {
        auth_service
          .getDataAuth(
            props.configuration.endpoint,
            jwt.TokenType,
            jwt.accessToken
          )
          .then((data) => {
            //console.log(props.configuration);
            setView(data.data.response_database.result[0]);
            setLoading(false);
          })
          .catch((err) => {
            if (err.status == 401) {
              window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
            }
            console.log(err);
          });
      } else {
        admin_service
          .getDataAuth(
            props.configuration.endpoint,
            jwt.TokenType,
            jwt.accessToken
          )
          .then((data) => {
            //console.log(props.configuration);
            setView(data.data.response_database.result[0]);
            setLoading(false);
          })
          .catch((err) => {
            if (err.status == 401) {
              window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
            }
            console.log(err);
          });
      }
    }
  }, [contador, props.configuration]);
  return (
    <React.Fragment>
      <CssBaseline />
      <Grid
        container
        style={{ width: "100%" }}
        alignItems="center"
        justifyContent="center"
      >
        {loading || props.configuration == null ? (
          <CircularProgress />
        ) : (
          <Grid item xs={12} md={12} lg={12}>
            <Stack spacing={5}>
              <Typography variant="h2">{props.configuration.name}</Typography>
              <ActionBar
                configuration={props.configuration}
                view={view}
                actionAfterDelete={props.actionAfterDelete}
              />
              <Attributes configuration={props.configuration} view={view} />
            </Stack>
          </Grid>
        )}
      </Grid>
    </React.Fragment>
  );
};

export default View;
