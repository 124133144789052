import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Alert,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
import Read from "../../utils/read/Read";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import FormatoAsesorComercial from "./FormatoAsesorComercial";
import FormatoFacilitador from "./FormatoFacilitador";
import FormatoCoordinacionAcademica from "./FormatoCoordinacionAcademica";
import useDataUsr from "../../hooks/useDataUsr";
import Cookies from "js-cookie";
import Progress from "./Progress";
import moment from "moment-timezone";
import Grid from "@mui/material/Grid";
const admin_service = require("../../helpers/admin_service");
function CustomTabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
    </div>
  );
}

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}
const ViewPropuesta = () => {
  const { id } = useParams();
  const [value, setValue] = React.useState(0);
  const { dataUsr, error, loading } = useDataUsr();
  const [propuesta, setPropuesta] = useState(null);

  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos_propuesta) => {
          setPropuesta(datos_propuesta.data.response_database.result[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const aprobarSolicitud = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .putDataAuth(
        "/propuesta-in-house/" + id,
        jwt.TokenType,
        jwt.accessToken,
        { estado: "APROBADO POR DIRECCIÓN ACADÉMICA" }
      )
      .then((response_db) => {
        window.location.href = `/in-house/view/${id}`;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const revisarSolicitud = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .putDataAuth(
        "/propuesta-in-house/" + id,
        jwt.TokenType,
        jwt.accessToken,
        { estado: "REVISIÓN Y CAMBIOS" }
      )
      .then((response_db) => {
        window.location.href = `/in-house/view/${id}`;
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const doPDF = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth(
        "/propuesta-in-house/do-pdf/" + id,
        jwt.TokenType,
        jwt.accessToken
      )
      .then((response) => {
        window.open(response.data.response.result.Location, "_blank");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Inicio
          </Link>
          <Link underline="hover" color="inherit" href={"/in-house"}>
            Propuestas In House
          </Link>
          <Typography color="text.primary">{id}</Typography>
        </Breadcrumbs>
        <Stack spacing={5} style={{ width: "100%" }}>
          {propuesta != null && (
            <Stack style={{ width: "100%" }} spacing={5}>
              <Grid container justifyContent="center">
                <Grid xs={12} md={12} lg={6} style={{ textAlign: "center" }}>
                  <Typography variant="h6">
                    {" "}
                    ESTADO DE PROPUESTA: {propuesta.estado}
                  </Typography>
                </Grid>
                <Grid style={{ textAlign: "center" }} xs={12} md={12} lg={6}>
                  <Stack direction="row" spacing={5} justifyContent="center">
                    <Typography variant="h6">PROGRESO DE PROPUESTA</Typography>
                    <Progress propuesta={propuesta} />
                  </Stack>
                </Grid>
              </Grid>

              {moment()
                .tz("America/Guatemala")
                .diff(
                  moment(propuesta.fecha_registro).tz("America/Guatemala"),
                  "days"
                ) == 0 ? (
                <Alert severity="success"> Propuesta Registrada Hoy</Alert>
              ) : (
                <Alert
                  severity={
                    moment()
                      .tz("America/Guatemala")
                      .diff(
                        moment(propuesta.fecha_registro).tz(
                          "America/Guatemala"
                        ),
                        "days"
                      ) > 5 &&
                    moment()
                      .tz("America/Guatemala")
                      .diff(
                        moment(propuesta.fecha_registro).tz(
                          "America/Guatemala"
                        ),
                        "days"
                      ) < 10
                      ? "warning"
                      : "error"
                  }
                >
                  Propuesta Registrada hace{" "}
                  {moment()
                    .tz("America/Guatemala")
                    .diff(
                      moment(propuesta.fecha_registro).tz("America/Guatemala"),
                      "days"
                    )}{" "}
                  dias{" "}
                </Alert>
              )}
            </Stack>
          )}
          {dataUsr && (
            <View
              actionAfterDelete={() => {
                window.location.href = `/in-house`;
              }}
              configuration={{
                endpoint: "/propuesta-in-house/view/" + id,
                key: "id",
                withActions: false,
                name: "SOLICITUD IN HOUSE",
                admin: true,
                properties: [
                  {
                    label: "Fecha y hora de registro",
                    field: "fecha_registro",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Fecha y hora ultima actualizacion",
                    field: "fecha_actualizacion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Institución",
                    field: "institucion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Asesor Comercial",
                    field: "asesor",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Estado de Solicitud",
                    field: "estado",
                    type: 1,
                    icon: "info",
                  },
                ],
                extraActions:
                  dataUsr.permisos == "DIRECCION-ACADEMICA" &&
                  propuesta != null &&
                  propuesta.estado != "APROBADO POR DIRECCIÓN ACADÉMICA"
                    ? [
                        {
                          configuration: {
                            type: 2,
                            icon: "check",
                            title: "Aprobar Solicitud",
                            action: aprobarSolicitud,
                          },
                        },
                        {
                          configuration: {
                            type: 2,
                            icon: "check",
                            title: "Enviar a revisión y cambios",
                            action: revisarSolicitud,
                          },
                        },
                      ]
                    : dataUsr.permisos == "DIRECCION-ACADEMICA" &&
                      propuesta != null &&
                      propuesta.estado == "APROBADO POR DIRECCIÓN ACADÉMICA"
                    ? [
                        {
                          configuration: {
                            type: 2,
                            icon: "check",
                            title: "Enviar a revisión y cambios",
                            action: revisarSolicitud,
                          },
                        },
                        {
                          configuration: {
                            type: 2,
                            icon: "pdf",
                            title: "Generar PDF",
                            action: doPDF,
                          },
                        },
                      ]
                    : propuesta != null &&
                      propuesta.estado == "APROBADO POR DIRECCIÓN ACADÉMICA"
                    ? [
                        {
                          configuration: {
                            type: 2,
                            icon: "pdf",
                            title: "Generar PDF",
                            action: doPDF,
                          },
                        },
                      ]
                    : null,
              }}
            />
          )}
          <Box sx={{ width: "100%", marginTop: "75px" }}>
            <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
              <Tabs
                value={value}
                onChange={handleChange}
                aria-label="basic tabs example"
              >
                <Tab label="SOLICITUD CLIENTE" {...a11yProps(0)} />
                <Tab label="FORMATO ASESOR COMERCIAL" {...a11yProps(1)} />
                <Tab label="FORMATO FACILITADOR" {...a11yProps(2)} />
                <Tab label="PROPUESTA TÉCNICA Y ECONÓMICA" {...a11yProps(3)} />
              </Tabs>
            </Box>
            <CustomTabPanel value={value} index={0}>
              <Stack spacing={5}>
                <View
                  actionAfterDelete={() => {
                    window.location.href = `/in-house`;
                  }}
                  configuration={{
                    endpoint: "/propuesta-in-house/view-cliente/" + id,
                    key: "id",
                    withActions: false,
                    name: "SOLICITUD CLIENTE",
                    admin: true,
                    properties: [
                      {
                        label: "Nombre de la institución",
                        field: "institucion",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Título del solicitante",
                        field: "titulo",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Nombre del solicitante",
                        field: "nombre",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Cargo del solicitante",
                        field: "cargo",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Correo del solicitante",
                        field: "email",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Área a la que va dirigida la capacitación",
                        field: "area",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Tipo de actividad que se requiere",
                        field: "tipo_actividad",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Fecha tentativa para el inicio evento",
                        field: "fecha_tentativa_inicio",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Fecha tentativa para el fin evento",
                        field: "fecha_tentativa_fin",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Horario tentativo de inicio",
                        field: "horario_tentativo_inicio",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Horario tentativo de fin",
                        field: "horario_tentativo_fin",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Días requeridos",
                        field: "dias_requeridos",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label:
                          "Cantidad de horas disponibles para la capacitación",
                        field: "cantidad_horas",
                        type: 1,
                        icon: "info",
                      },

                      {
                        label: "Alcance de la capacitación",
                        field: "alcance",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Brecha que desean cerrar",
                        field: "brecha",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Modalidad de preferencia",
                        field: "modalidad",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "No. de participantes",
                        field: "participantes",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Nivel de capacitación",
                        field: "nivel",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Perfil de los participantes",
                        field: "perfil",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Requiere diploma de participación impreso",
                        field: "requiere_diploma",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Requiere certificado impreso",
                        field: "requiere_certificado",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Requiere material (presentaciones) impreso",
                        field: "requiere_material",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label:
                          "Especificaciones sobre la experiencia del facilitador",
                        field: "especificacion",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Lugar para las capacitaciones",
                        field: "lugar",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label: "Ubicación de los participantes",
                        field: "ubicacion",
                        type: 1,
                        icon: "info",
                      },
                      {
                        label:
                          "Posee un protocolo específico para la apertura de eventos",
                        field: "protocolo",
                        type: 1,
                        icon: "info",
                      },
                    ],
                  }}
                />

                <Read
                  config={{
                    title: "TEMAS PROPUESTOS",
                    endpoint: "/propuesta-in-house/read-temas/" + id,
                    id: "id",
                    admin: true,
                    headers: [
                      {
                        id: "tema",
                        numeric: false,
                        disablePadding: true,
                        label: "Tema",
                      },
                    ],
                  }}
                />
              </Stack>
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
              <FormatoAsesorComercial id={id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={2}>
              <FormatoFacilitador id={id} />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={3}>
              <FormatoCoordinacionAcademica id={id} />
            </CustomTabPanel>
          </Box>
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewPropuesta;
