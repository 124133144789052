import React, { useEffect, useState } from "react";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";
import ListItemAvatar from "@mui/material/ListItemAvatar";
import Avatar from "@mui/material/Avatar";
import Paper from "@mui/material/Paper";
import Icons from "../icons/Icons";
import IconButton from "@mui/material/IconButton";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import DOMPurify from "dompurify";
import { CircularProgress } from "@mui/material";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const Attributes = (props) => {
  const [cambios, setCambios] = useState(0);
  const [open, setOpen] = React.useState(false);
  const [media, setMedia] = useState(null);
  const handleClickOpen = () => {
    setOpen(true);
    setCambios(cambios + 1);
  };
  const handleClose = () => {
    setOpen(false);
    setMedia(null);
    setCambios(cambios + 1);
  };
  useEffect(() => {}, [cambios]);

  const doAction = (configuration) => {
    if (configuration.type == 3) {
      window.open(props.view[configuration.field], "_blank");
    } else {
      setMedia({
        url: props.view[configuration.field],
        type: configuration.type,
      });
      setCambios(cambios + 1);
      handleClickOpen();
    }
  };
  return props.view != null ? (
    <Paper elevation={5}>
      <List sx={{ width: "100%" }}>
        {props.configuration.properties.map((actual, index) => (
          <ListItem
            key={`propiedad-${index}`}
            secondaryAction={
              (actual.type == 2 ||
                actual.type == 4 ||
                actual.type == 3 ||
                actual.type == 5 ||
                actual.type == 6) &&
              props.view[actual.field] != null ? (
                <IconButton
                  edge="end"
                  aria-label="Vista Previa"
                  onClick={() => {
                    doAction(actual);
                  }}
                >
                  <VisibilityIcon />
                </IconButton>
              ) : null
            }
          >
            <ListItemAvatar>
              <Avatar>
                <Icons icon={actual.icon} />
              </Avatar>
            </ListItemAvatar>
            <ListItemText
              primary={actual.label}
              secondary={
                actual.type == 6 ? (
                  ""
                ) : actual.type == 7 ? (
                  <div
                    className="mb-5"
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(props.view[actual.field]),
                    }}
                  ></div>
                ) : (
                  props.view[actual.field]
                )
              }
            />
          </ListItem>
        ))}
      </List>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
        fullScreen
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Vista previa
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: "absolute",
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          {media == null && <CircularProgress />}
          {media != null && media.type == 2 && (
            <img
              src={media.url}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
              alt="vista-previa"
            />
          )}
          {media != null && media.type == 4 && (
            <video
              controls
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            >
              <source src={media.url} type="video/mp4" />
            </video>
          )}
          {media != null && media.type == 5 && (
            <iframe
              src={media.url}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            ></iframe>
          )}
          {media != null && media.type == 6 && (
            <iframe
              srcdoc={media.url}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            ></iframe>
          )}
        </DialogContent>
      </BootstrapDialog>
    </Paper>
  ) : (
    <Paper></Paper>
  );
};

export default Attributes;
