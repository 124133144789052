import { useLocation, Navigate, Outlet } from "react-router-dom";
import useDataUsr from "../../hooks/useDataUsr";
import { useEffect, useState, React } from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Cookies from "js-cookie";

const auth_service = require("../../helpers/authService");

const RequireHardToken = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    if (dataUsr) {
      if (dataUsr.opt_token != null && dataUsr.opt_token_exp != null) {
        auth_service
          .postDataAuth(
            "/usr/validate-hard-token",
            jwt.TokenType,
            jwt.accessToken,
            { username: dataUsr.Username, mfa: dataUsr.opt_token }
          )
          .then((data) => {
            setCargando(false);
          })
          .catch((err) => {
            console.log(err);
            window.location.href = `/secrets/authenticate`;
          });
      } else {
        window.location.href = `/secrets/authenticate`;
      }
    }
  }, [dataUsr]);
  if (!cargando) {
    return <Outlet />;
  } else {
    return (
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    );
  }
};

export default RequireHardToken;
