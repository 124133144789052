import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import useDataUsr from "../../hooks/useDataUsr";

const ReadInHouse = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    if (dataUsr) {
      setCargando(false);
    }
  }, [dataUsr]);
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Eventos In House</Typography>
          </Breadcrumbs>
          {dataUsr && (
            <Read
              config={{
                title: "Propuestas registradas",
                endpoint:
                  dataUsr.permisos == "COMERCIAL" ||
                  dataUsr.permisos == "REDES SOCIALES"
                    ? "/propuesta-in-house/read-asesor/" + dataUsr.asesor_id
                    : "/propuesta-in-house/read",
                id: "id",
                onDelete:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? "/propuesta-in-house"
                    : null,
                onView: `/in-house/view`,
                admin: true,
                headers:
                  dataUsr.permisos == "COMERCIAL" ||
                  dataUsr.permisos == "REDES SOCIALES"
                    ? [
                        {
                          id: "fecha_registro",
                          numeric: false,
                          disablePadding: true,
                          label: "Fecha Registro",
                        },
                        {
                          id: "institucion",
                          numeric: false,
                          disablePadding: true,
                          label: "Institución",
                        },
                        {
                          id: "estado",
                          numeric: false,
                          disablePadding: true,
                          label: "Estado",
                        },
                      ]
                    : [
                        {
                          id: "fecha_registro",
                          numeric: false,
                          disablePadding: true,
                          label: "Fecha Registro",
                        },
                        {
                          id: "institucion",
                          numeric: false,
                          disablePadding: true,
                          label: "Institución",
                        },
                        {
                          id: "estado",
                          numeric: false,
                          disablePadding: true,
                          label: "Estado",
                        },
                        {
                          id: "asesor",
                          numeric: false,
                          disablePadding: true,
                          label: "Asesor Comercial",
                        },
                      ],
              }}
            />
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadInHouse;
