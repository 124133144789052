import React from "react";
import { Typography, Stack, Breadcrumbs, Link, Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
const ViewFotoInventarioTI = () => {
  const { inventario, id } = useParams();

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>

            <Link underline="hover" color="inherit" href={"/inventario-ti"}>
              Inventario IT
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={"/inventario-ti/view/" + inventario}
            >
              {inventario}
            </Link>
            <Typography color="text.primary">
              Ver fotografia o documentacion
            </Typography>
          </Breadcrumbs>

          <View
            actionAfterDelete={() => {
              window.location.href = `/inventario-ti/view/` + inventario;
            }}
            configuration={{
              endpoint: "/fotografias-inventario-ti/view/" + id,
              onDelete: "/fotografias-inventario-ti",
              key: "id",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar la fotografia o documentacion seleccionada?",
              name: "Fotografía o documentación",
              admin: true,
              properties: [
                {
                  label: "Fecha de Registro",
                  field: "fecha_registro",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Descripción",
                  field: "descripcion",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Imagen o documento",
                  field: "url",
                  type: 3,
                  icon: "url",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewFotoInventarioTI;
