import { Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import useRefreshToken from "../hooks/useRefreshToken";
const PersistLogin = () => {
  const [isLoading, setIsLoading] = useState(true);
  const refresh = useRefreshToken();
  useEffect(() => {
    const verifyRefreshToken = async () => {
      try {
        await refresh();        
      } catch (err) {
        console.log(err);
      } finally {
        setIsLoading(false);
      }
    };
    verifyRefreshToken();
  }, []);

  useEffect(() => {}, [isLoading]);

  return (
    <>
      {isLoading ? (
        <div
          style={{
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center center",
            backgroundSize: "cover",
            width: "100vw",
            height: "100vh",
            overflow: "hidden",
            backgroundImage:
              "url(https://escuela-bancaria.s3.us-east-2.amazonaws.com/Captura+de+pantalla+2023-08-04+a+la(s)+14.41.43+(1).png)",
          }}
        ></div>
      ) : (
        <Outlet />
      )}
    </>
  );
};
export default PersistLogin;
