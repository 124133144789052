import * as React from "react";
import PropTypes from "prop-types";
import CircularProgress from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

function CircularProgressWithLabel(props) {
  return (
    <Box sx={{ position: "relative", display: "inline-flex" }}>
      <CircularProgress variant="determinate" {...props} />
      <Box
        sx={{
          top: 0,
          left: 0,
          bottom: 0,
          right: 0,
          position: "absolute",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Typography
          variant="caption"
          component="div"
          sx={{ color: "text.secondary" }}
        >
          {`${Math.round(props.value)}%`}
        </Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  /**
   * The value of the progress indicator for the determinate variant.
   * Value between 0 and 100.
   * @default 0
   */
  value: PropTypes.number.isRequired,
};

export default function Progress(props) {
  const [progress, setProgress] = React.useState(10);

  React.useEffect(() => {
    if (props.propuesta.estado == "PROPUESTA ENVIADA") {
      setProgress(0);
    } else if (props.propuesta.estado == "RECIBIDA POR ASESOR COMERCIAL") {
      setProgress(20);
    } else if (props.propuesta.estado == "DESARROLLO PROPUESTA TÉCNICA") {
      setProgress(40);
    } else if (
      props.propuesta.estado == "DESARROLLO PROPUESTA TÉCNICO-ECONÓMICA"
    ) {
      setProgress(60);
    } else if (props.propuesta.estado == "ENVIADO A DIRECCIÓN ACADÉMICA") {
      setProgress(75);
    } else if (props.propuesta.estado == "REVISIÓN Y CAMBIOS") {
      setProgress(80);
    } else if (props.propuesta.estado == "APROBADO POR DIRECCIÓN ACADÉMICA") {
      setProgress(85);
    } else if (props.propuesta.estado == "ENVIADO A CLIENTE") {
      setProgress(95);
    } else if (props.propuesta.estado == "APROBADO POR CLIENTE") {
      setProgress(100);
    }
  }, []);

  return <CircularProgressWithLabel value={progress} />;
}
