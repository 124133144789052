import { useLocation, Navigate, Outlet } from "react-router-dom";
import useDataUsr from "../hooks/useDataUsr";
const RequireAuth = ({ allowedRoles }) => {
  //rol_id
  const { dataUsr, error, loading } = useDataUsr();
  const location = useLocation();
  if (dataUsr) {
    return allowedRoles?.find((role) => role == dataUsr.permisos) ? (
      <Outlet />
    ) : dataUsr ? (
      <Navigate to="/unauthorized" state={{ from: location }} replace />
    ) : (
      <Navigate to={process.env.REACT_APP_REDIRECT_ENDPOINT} state={{ from: location }} replace />
    );
  }
};

export default RequireAuth;
