import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { alpha } from "@mui/material/styles";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import TableSortLabel from "@mui/material/TableSortLabel";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import DeleteIcon from "@mui/icons-material/Delete";
import { visuallyHidden } from "@mui/utils";
import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import EditIcon from "@mui/icons-material/Edit";
import Cookies from "js-cookie";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Grid from "@mui/material/Unstable_Grid2";
import Button from "@mui/material/Button";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import TextField from "@mui/material/TextField";
import FormControl from "@mui/material/FormControl";
import { Stack } from "@mui/material";
import Icons from "../icons/Icons";
const admin_service = require("../../helpers/admin_service");
const auth_service = require("../../helpers/authService");
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
  const {
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
          />
        </TableCell>
        {props.headers.map((headCell, index) => (
          <TableCell
            key={`headcell-${index}`}
            align="left"
            padding={headCell.disablePadding ? "none" : "normal"}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : "asc"}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === "desc" ? "sorted descending" : "sorted ascending"}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(["asc", "desc"]).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

function EnhancedTableToolbar(props) {
  const { numSelected } = props;
  const [openAskModal, setOpenAskModal] = useState(false);
  const [cambios, setCambios] = useState(0);
  useEffect(() => {}, [cambios]);
  const onDelete = () => {
    setOpenAskModal(true);
    setCambios(cambios + 1);
  };
  const onUpdate = () => {
    window.location.href = `${props.config.onUpdate}/${props.selected[0]}`;
  };
  const onView = () => {
    window.location.href = `${props.config.onView}/${props.selected[0]}`;
  };
  const doDelete = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    if (props.config.admin) {
      admin_service
        .deleteDataAuth(props.config.onDelete, jwt.TokenType, jwt.accessToken, {
          rows: props.selected,
        })
        .then((data) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      auth_service
        .deleteDataAuth(props.config.onDelete, jwt.TokenType, jwt.accessToken, {
          rows: props.selected,
        })
        .then((data) => {
          window.location.reload();
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };

  return (
    <Toolbar
      sx={{
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(
              theme.palette.primary.main,
              theme.palette.action.activatedOpacity
            ),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: "1 1 100%" }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected > 1
            ? numSelected + " seleccionados"
            : numSelected + " seleccionado"}
        </Typography>
      ) : (
        <Typography
          sx={{ flex: "1 1 100%" }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          {props.config && props.config.title}
        </Typography>
      )}
      {numSelected == 1 && props.config.onView && (
        <Tooltip>
          <IconButton onClick={onView}>
            <RemoveRedEyeIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected == 1 && props.config.onUpdate && (
        <Tooltip>
          <IconButton onClick={onUpdate}>
            <EditIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected == 1 && props.config.onDelete && (
        <Tooltip>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {numSelected > 1 && props.config.onDelete && (
        <Tooltip>
          <IconButton onClick={onDelete}>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      )}
      {props.config.extraActions != null &&
        props.config.extraActions.map((actual, key) => {
          if (numSelected >= 1) {
            return (
              <Tooltip key={`extra-action-${key}`}>
                <IconButton
                  onClick={() => {
                    actual.configuration.action(props.selected);
                  }}
                >
                  <Icons icon={actual.configuration.icon} />
                </IconButton>
              </Tooltip>
            );
          }
        })}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAskModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAskModal}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  SE NECESITA CONFIRMACIÓN
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  ¿Estás segur@ de elminar los items seleccionados?
                </Typography>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<DeleteOutlineIcon />}
                  color="error"
                  onClick={doDelete}
                >
                  Si
                </Button>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <Button
                  tabIndex={-1}
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setOpenAskModal(false);
                    setCambios(cambios + 1);
                  }}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </Toolbar>
  );
}

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function ReadData(props) {
  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [dense] = React.useState(false);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);
  const [criterio, setCriterio] = useState("");
  const [cambios, setCambios] = useState(0);
  const [info, setInfo] = useState([]);
  const [data, setData] = useState([]);
  useEffect(() => {}, [cambios]);
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    if (props.config.admin) {
      admin_service
        .getDataAuth(props.config.endpoint, jwt.TokenType, jwt.accessToken)
        .then((data) => {
          setInfo(data.data.response.result);
          setData(data.data.response.result);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      auth_service
        .getDataAuth(props.config.endpoint, jwt.TokenType, jwt.accessToken)
        .then((data) => {
          setInfo(data.data.response.result);
          setData(data.data.response.result);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [props.config]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelected = data.map((n) => n[props.config.id]);
      setSelected(newSelected);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, id) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, id);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const isSelected = (id) => selected.indexOf(id) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - data.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(data, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [order, orderBy, page, rowsPerPage, data]
  );
  const onChange = (evt) => {
    let result = info.filter((actual) => {
      return props.config.headers.reduce(
        (accumulator, currentValue) =>
          accumulator ||
          actual[currentValue.id].toString().search(evt.target.value) != -1,
        false
      );
    });
    setData(result);
    setCriterio(evt.target.value);
    setCambios(cambios);
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Paper sx={{ width: "100%", mb: 2 }}>
        <Stack spacing={5}>
          <EnhancedTableToolbar
            numSelected={selected.length}
            selected={selected}
            config={props.config}
          />
          <FormControl fullWidth variant="outlined">
            <TextField
              label={"Buscar"}
              value={criterio}
              onChange={onChange}
              fullWidth
              type="text"
            />
          </FormControl>
          <TableContainer>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size={dense ? "small" : "medium"}
            >
              <EnhancedTableHead
                numSelected={selected.length}
                order={order}
                orderBy={orderBy}
                onSelectAllClick={handleSelectAllClick}
                onRequestSort={handleRequestSort}
                rowCount={data.length}
                headers={props.config.headers}
              />
              <TableBody>
                {visibleRows.map((row, index) => {
                  const isItemSelected = isSelected(row[props.config.id]);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      onClick={(event) =>
                        handleClick(event, row[props.config.id])
                      }
                      role="checkbox"
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={labelId}
                      selected={isItemSelected}
                      sx={{ cursor: "pointer" }}
                    >
                      <TableCell padding="checkbox">
                        <Checkbox
                          color="primary"
                          checked={isItemSelected}
                          inputProps={{
                            "aria-labelledby": labelId,
                          }}
                        />
                      </TableCell>
                      {props.config &&
                        props.config.headers.map((actual, indice) => (
                          <TableCell
                            key={`celda-${indice}`}
                            component="th"
                            id={labelId}
                            scope="row"
                            padding="none"
                            align="left"
                          >
                            {row[actual.id]}
                          </TableCell>
                        ))}
                    </TableRow>
                  );
                })}
                {emptyRows > 0 && (
                  <TableRow
                    style={{
                      height: (dense ? 33 : 53) * emptyRows,
                    }}
                  >
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={data.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Stack>
      </Paper>
    </Box>
  );
}
