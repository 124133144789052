import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import Formulario from "../../utils/forms/Formulario";
import useDataUsr from "../../hooks/useDataUsr";

const admin_service = require("../../helpers/admin_service");

const s3_service = require("../../helpers/s3_service");
const UpdateAsesorProfile = () => {
  const [asesor, setAsesor] = useState({});
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);
  const { dataUsr, error, cargando } = useDataUsr();

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    if (dataUsr) {
      admin_service
        .getDataAuth(
          "/asesor/view/" + dataUsr.asesor_id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setAsesor(datos.data.response_database.result[0]);
          setFormConfig({
            title: `Actualizar información perfil asesor comercial "${datos.data.response_database.result[0].Nombres} ${datos.data.response_database.result[0].Apellidos}"`,
            submitTitle: "Guardar información",
            formConfig: [
              {
                type: 1,
                required: true,
                value: datos.data.response_database.result[0].Nombres,
                name: "Nombres",
                label: "Ingrese los nombres del asesor",
                title: "Nombres de asesor",
              },
              {
                type: 1,
                required: true,
                value: datos.data.response_database.result[0].Apellidos,
                name: "Apellidos",
                label: "Ingrese los apellidos del asesor",
                title: "Apellidos de asesor",
              },

              {
                type: 6,
                required: false,
                value: null,
                name: "foto",
                label: "Ingrese la foto del asesor",
                title: "Foto del asesor",
                accept: "image/png, image/gif, image/jpeg",
              },
              {
                type: 4,
                required: false,
                value: datos.data.response_database.result[0].email,
                name: "email",
                label: "Ingrese el email del asesor",
                title: "Correo electrónico de asesor",
              },
              {
                type: 1,
                required: false,
                value: datos.data.response_database.result[0].puesto,
                name: "puesto",
                label: "Ingrese el puesto del asesor",
                title: "Puesto de asesor",
              },
              {
                type: 1,
                required: false,
                value: datos.data.response_database.result[0].whatsapp,
                name: "whatsapp",
                label: "Ingrese el whatsapp del asesor",
                title: "Contacto de whatsapp",
              },

              {
                type: 6,
                required: false,
                value: null,
                name: "firma_mail",
                label: "Ingrese la firma de correo electrónico de asesor",
                title: "Firma de correo electrónico de asesor",
                accept: "image/png, image/gif, image/jpeg",
              },
            ],
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.foto)
        .then((response_bucket) => {
          body.foto =
            response_bucket != null ? response_bucket.result.Location : null;
          s3_service
            .saveFile(body.firma_mail)
            .then((response_bucket_2) => {
              body.firma_mail =
                response_bucket_2 != null
                  ? response_bucket_2.result.Location
                  : null;
              body.cognito_sub = dataUsr.sub;
              admin_service
                .putDataAuth(
                  "/asesor/" + dataUsr.asesor_id,
                  jwt.TokenType,
                  jwt.accessToken,
                  body
                )
                .then((response_database) => {
                  window.location.href = `/asesor-profile`;
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject(
                    "ocurrido un error al guardar la información del asesor, intente de nuevo"
                  );
                });
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la imagen del asesor, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del asesor, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>

            <Link underline="hover" color="inherit" href={`/asesor-profile`}>
              Perfil Asesor Comercial {asesor.Nombres + " " + asesor.Apellidos}
            </Link>
            <Typography color="text.primary">Actualizar Perfil</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UpdateAsesorProfile;
