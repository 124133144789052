import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
const auth_service = require("../../helpers/authService");
const s3_service = require("../../helpers/s3_service");
const CreateUser = () => {
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    setFormConfig({
      title: `Registro de Información de Usuario`,
      submitTitle: "Guardar información",
      formConfig: [
        {
          type: 1,
          required: true,
          value: null,
          name: "username",
          label: "Ingrese el nombre de usuario",
          title: "Nombre de usuario",
        },
        {
          type: 4,
          required: true,
          value: null,
          name: "email",
          label: "Ingrese el email del usuario",
          title: "Correo electrónico de usuario",
        },
        {
          type: 1,
          required: true,
          value: null,
          name: "given_name",
          label: "Ingrese los nombres del usuario",
          title: "Nombres de usuario",
        },
        {
          type: 1,
          required: true,
          value: null,
          name: "family_name",
          label: "Ingrese los apellidos del usuario",
          title: "Apellidos de usuario",
        },
        {
          type: 16,
          required: true,
          value: null,
          name: "gender",
          label: "Seleccione el género del usuario",
          title: "Género de Usuario",
          options: [
            { label: "FEMENINO", value: "F" },
            { label: "MASCULINO", value: "M" },
          ],
        },
        {
          type: 6,
          required: true,
          value: null,
          name: "picture",
          label: "Ingrese la foto del participante",
          title: "Foto del participante",
          accept: "image/png, image/gif, image/jpeg",
        },
        {
          type: 16,
          required: true,
          value: null,
          name: "permisos",
          label: "Seleccione los permisos del usuario",
          title: "Permisos de Usuario",
          options: [
            { label: "ADMINISTRADOR", value: "ADMINISTRADOR" },
            { label: "DIRECCION-ACADEMICA", value: "DIRECCION-ACADEMICA" },
            { label: "CONTROL-ACADEMICO", value: "CONTROL-ACADEMICO" },
            { label: "CONTABILIDAD", value: "CONTABILIDAD" },
            { label: "DISEÑO", value: "DISEÑO" },
            { label: "COMERCIAL", value: "COMERCIAL" },
            { label: "REDES SOCIALES", value: "REDES SOCIALES" },
          ],
        },
      ],
    });
    setLoading(false);
  }, [contador]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.picture)
        .then((response_bucket) => {
          body.picture =
            response_bucket != null ? response_bucket.result.Location : null;
          auth_service
            .postDataAuth("/usr", jwt.TokenType, jwt.accessToken, body)
            .then((response_database) => {
              console.log(response_database);
              window.location.href = `/users/view/${response_database.data.cognito.response.UserSub}`;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/users"}>
              Usuarios
            </Link>
            <Typography color="text.primary">Registrar Usuario</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateUser;
