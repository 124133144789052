import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Button,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

const ReadUsers = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {}, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Usuarios</Typography>
          </Breadcrumbs>
          <Button
            variant="contained"
            tabIndex={-1}
            startIcon={<PersonIcon />}
            href={`/users/create`}
            sx={{ width: { xs: "100%", md: "100%", lg: "35%" } }}
          >
            Registrar Usuario
          </Button>
          <Read
            config={{
              title: "Usuarios registrados",
              endpoint: "/usr",
              id: "sub",
              onDelete: "/usr",
              onView: `/users/view`,
              onUpdate: `/users/update`,
              headers: [
                {
                  id: "Username",
                  numeric: false,
                  disablePadding: true,
                  label: "Usuario",
                },
                {
                  id: "given_name",
                  numeric: false,
                  disablePadding: true,
                  label: "Nombres",
                },
                {
                  id: "family_name",
                  numeric: false,
                  disablePadding: true,
                  label: "Apellidos",
                },
                {
                  id: "email",
                  numeric: false,
                  disablePadding: true,
                  label: "Correo Electrónico",
                },
                {
                  id: "permisos",
                  numeric: false,
                  disablePadding: true,
                  label: "Permisos",
                },
                {
                  id: "sub",
                  numeric: false,
                  disablePadding: true,
                  label: "Identificador",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadUsers;
