import { Editor } from "@tinymce/tinymce-react";
import React from "react";
import Box from "@mui/material/Box";

export default function RichText(props) {
  return (
    <Box style={props.style}>
      <div style={{ width: "100%" }}>
        <Editor
          apiKey={process.env.REACT_APP_API_KEY_EDITOR}
          init={{
            plugins: "lists link paste wordcount",
            toolbar:
              "undo redo | blocks | bold italic | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent",
            images_upload_url: "postAcceptor.php",
            automatic_uploads: false,
          }}
          value={props.conf.value}
          id={props.index + "-" + props.conf.name}
          onEditorChange={props.onChange}
          disabled={props.disabled}
        />
      </div>
    </Box>
  );
}
