import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../../utils/view/View";
import Read from "../../../utils/read/Read";
import useDataUsr from "../../../hooks/useDataUsr";
const admin_service = require("../../../helpers/admin_service");
const ViewSeguimientoTicket = () => {
  const { ticket_id, id } = useParams();
  const [contador] = useState(0);
  const [cargando, setCargando] = useState(true);
  const [ticket, setTicket] = useState({});
  const [seguimientoTicket, setSeguimientoTicket] = useState({});

  const { dataUsr, error, loading } = useDataUsr();
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth(
        "/ticket-soporte/view/" + ticket_id,
        jwt.TokenType,
        jwt.accessToken
      )
      .then((datos) => {
        admin_service
          .getDataAuth(
            "/ticket-soporte/view/" + ticket_id,
            jwt.TokenType,
            jwt.accessToken
          )
          .then((datos_seguimiento) => {
            setTicket(datos.data.response_database.result[0]);
            setSeguimientoTicket(
              datos_seguimiento.data.response_database.result[0]
            );
            setCargando(false);
          })
          .catch((err) => {
            console.log(err);
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/ticket-soporte"}>
              Tickets Soporte IT
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={"/ticket-soporte/view/" + ticket_id}
            >
              {ticket.id}
            </Link>
            <Typography color="text.primary">Seguimiento de ticket</Typography>
          </Breadcrumbs>
          {dataUsr && (
            <View
              actionAfterDelete={() => {
                window.location.href = `/ticket-soporte/view/${ticket_id}`;
              }}
              configuration={{
                endpoint: "/seguimiento-ticket/view/" + id,
                key: "id",
                withActions: true,
                confirmationMensage:
                  "¿Está segur@ de eliminar el seguimiento seleccionado?",
                name: "Seguimiento Ticket",
                admin: true,
                properties: [
                  {
                    label: "Fecha de Registro",
                    field: "fecha_registro",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Identificador de Seguimiento",
                    field: "id",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Usuario",
                    field: "usuario",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Descripción de seguimiento",
                    field: "descripcion",
                    type: 7,
                    icon: "info",
                  },
                ],
              }}
            />
          )}
          <Read
            config={{
              title: "Archivos adjuntos",
              endpoint: `/seguimiento-ticket/view-files/${id}`,
              onView: `/ticket-soporte/seguimiento/${ticket_id}/view-file/${id}`,
              id: "key",
              admin: true,
              headers: [
                {
                  id: "file",
                  numeric: false,
                  disablePadding: true,
                  label: "Archivo",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewSeguimientoTicket;
