import { useEffect, useState } from "react";
import Cookies from "js-cookie";
const auth_service = require("../helpers/authService");
const admin_service = require("../helpers/admin_service");
export default function useDataUsr() {
  const [dataUsr, setDataUsr] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));

  useEffect(() => {
    (async function () {
      try {
        setLoading(true);
        auth_service
          .getDataAuth("/usr/get-usr", jwt.TokenType, jwt.accessToken)
          .then((data) => {
            if (
              data.data.cognito.permisos == "COMERCIAL" ||
              data.data.cognito.permisos == "REDES SOCIALES"
            ) {
              admin_service
                .getDataAuth(
                  "/asesor/view_sub/" + data.data.cognito.sub,
                  jwt.TokenType,
                  jwt.accessToken
                )
                .then((response) => {
                  let info = data.data.cognito;
                  info.asesor_id = response.data.response.result[0].UniqueID;
                  info.asesor_hash = response.data.response.result[0].hash;
                  setDataUsr(info);
                })
                .catch((error) => {
                  console.log(error);
                });
            } else {
              setDataUsr(data.data.cognito);
            }
          })
          .catch((err) => {
            Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
            console.log(err);
            window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
          });
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    })();
  }, []);

  return { dataUsr, error, loading };
}
