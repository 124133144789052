import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import View from "../../utils/view/View";
import useDataUsr from "../../hooks/useDataUsr";
const admin_service = require("../../helpers/admin_service");
const ViewAsesorProfile = () => {
  const [loading, setLoading] = useState(true);
  const [asesor, setAsesor] = useState({});
  const { dataUsr, error, cargando } = useDataUsr();

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    if (dataUsr) {
      admin_service
        .getDataAuth(
          "/asesor/view/" + dataUsr.asesor_id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setAsesor(datos.data.response_database.result[0]);
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>

            <Typography color="text.primary">
              Perfil Asesor Comercial: {asesor.Nombres + " " + asesor.Apellidos}
            </Typography>
          </Breadcrumbs>
          {dataUsr && (
            <View
              actionAfterDelete={() => {
                window.location.href = `/asesor`;
              }}
              configuration={{
                endpoint: "/asesor/view/" + dataUsr.asesor_id,
                onUpdate: `/asesor-profile/update`,
                key: "UniqueID",
                withActions: true,
                confirmationMensage:
                  "¿Está segur@ de eliminar el asesor seleccionado?",
                name: "Asesor",
                admin: true,
                properties: [
                  {
                    label: "Nombres",
                    field: "Nombres",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Apellidos",
                    field: "Apellidos",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Fotografía",
                    field: "foto",
                    type: 2,
                    icon: "image",
                  },
                  {
                    label: "Correo electrónico",
                    field: "email",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Puesto",
                    field: "puesto",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Contacto Whatsapp",
                    field: "whatsapp",
                    type: 1,
                    icon: "info",
                  },

                  {
                    label: "Teléfono institucional",
                    field: "telefono_institucion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "firma de email",
                    field: "firma_mail",
                    type: 2,
                    icon: "image",
                  },
                ],
              }}
            />
          )}
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewAsesorProfile;
