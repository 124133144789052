import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
const auth_service = require("../../helpers/authService");
const ViewUser = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [usr, setUsr] = useState({});
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    auth_service
      .getDataAuth("/usr/view/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setUsr(datos.data.response_database.result[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);
  const setPwd = () => {
    setLoading(true);
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    auth_service
      .getDataAuth("/usr/set-pwd/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/users"}>
              Usuarios
            </Link>
            <Typography color="text.primary">
              {usr.given_name + " " + usr.family_name}
            </Typography>
          </Breadcrumbs>
          <View
            actionAfterDelete={() => {
              window.location.href = `/users`;
            }}
            configuration={{
              endpoint: "/usr/view/" + id,
              onDelete: "/usr",
              onUpdate: `/users/update/${id}`,
              key: "sub",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar el usuario seleccionado?",
              name: "Usuario",
              auth: true,
              properties: [
                {
                  label: "Fecha de Registro",
                  field: "UserCreateDate",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Última Actualización",
                  field: "UserLastModifiedDate",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Nombre de Usuario",
                  field: "Username",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Correo Electrónico",
                  field: "email",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Nombres",
                  field: "given_name",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Apellidos",
                  field: "family_name",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Género",
                  field: "genero",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Permisos",
                  field: "permisos",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "foto",
                  field: "picture",
                  type: 2,
                  icon: "image",
                },
                {
                  label: "Identificador único",
                  field: "sub",
                  type: 1,
                  icon: "info",
                },
              ],
              extraActions: [
                {
                  configuration: {
                    type: 2,
                    icon: "key",
                    title: "Generar Contraseña Temporal",
                    action: setPwd,
                  },
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewUser;
