import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
const admin_service = require("../../helpers/admin_service");
const s3_service = require("../../helpers/s3_service");
const CreateDocente = () => {
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/tipo-experiencia", jwt.TokenType, jwt.accessToken)
      .then((response) => {
        setFormConfig({
          title: `Registrar docente`,
          submitTitle: "Guardar información",
          formConfig: [
            {
              type: 1,
              required: true,
              value: "",
              name: "Nombres",
              label: "Ingrese los nombres del docente",
              title: "Nombres de docente",
            },
            {
              type: 1,
              required: true,
              value: "",
              name: "Apellidos",
              label: "Ingrese los apellidos del docente",
              title: "Apellidos de docente",
            },
            {
              type: 4,
              required: true,
              value: "",
              name: "Correo",
              label: "Ingrese el email del docente",
              title: "Correo electrónico de docente",
            },
            {
              type: 1,
              required: true,
              value: "",
              name: "Telefono",
              label: "Ingrese el teléfono del docente",
              title: "Telefono de docente",
            },
            {
              type: 7,
              required: false,
              value: "",
              name: "Tarifa",
              label: "Ingrese la tarifa del docente",
              title: "Tarifa de docente",
            },
            {
              type: 6,
              required: false,
              value: null,
              name: "Foto",
              label: "Ingrese la foto del docente",
              title: "Foto del docente",
              accept: "image/png, image/gif, image/jpeg",
            },
            {
              type: 17,
              required: true,
              value: [],
              name: "experiencia",
              title: "Experiencia de docente",
              fields: [
                {
                  type: 8,
                  required: true,
                  value: "",
                  name: "descripcion",
                  label: "Ingresa una breve descripción de la experiencia",
                  title: "Descripción de experiencia",
                },
                {
                  type: 3,
                  required: true,
                  value: "",
                  name: "tipoExperiencia_UniqueID",
                  label: "Seleccione el tipo de experiencia",
                  title: "Tipo de Experiencia",
                  options: response.data.response.result.map(
                    (actual) => {
                      return {
                        label: actual.Nombre,
                        value: actual.UniqueID,
                      };
                    }
                  ),
                },
              ],
            },
          ],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.Foto)
        .then((response_bucket_2) => {
          body.Foto =
            response_bucket_2 != null
              ? response_bucket_2.result.Location
              : null;
          body.keyFoto =
            response_bucket_2 != null ? response_bucket_2.result.Key : null;
          admin_service
            .postDataAuth("/docente", jwt.TokenType, jwt.accessToken, body)
            .then((response_database) => {
              window.location.href = `/docente/view/${response_database.data.response_database.results[0].insertId}`;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la información del docente, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del docente, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/docente"}>
              Docentes
            </Link>

            <Typography color="text.primary">Registrar Docente</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateDocente;
