import React from "react";
import { Typography, Stack, Breadcrumbs, Link, Box } from "@mui/material";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
const ViewSecret = () => {
  const { id } = useParams();

  return (
    <React.Fragment>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/secrets"}>
              Secretos
            </Link>
            <Typography color="text.primary">{id}</Typography>
          </Breadcrumbs>
          <View
            actionAfterDelete={() => {
              window.location.href = `/secrets`;
            }}
            configuration={{
              endpoint: "/secrets/view/" + id,
              onDelete: "/secrets",
              onUpdate: `/secrets/update/${id}`,
              key: "id",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar el secreto seleccionado?",
              name: "Secreto",
              admin: true,
              properties: [
                {
                  label: "Nombre del secreto",
                  field: "secretName",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Secreto",
                  field: "decryptedData",
                  type: 1,
                  icon: "info",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewSecret;
