import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import Formulario from "../../utils/forms/Formulario";
const auth_service = require("../../helpers/authService");
const s3_service = require("../../helpers/s3_service");
const UpdateUser = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [usr, setUsr] = useState({});
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    auth_service
      .getDataAuth("/usr/view/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setUsr(datos.data.response_database.result[0]);
        setFormConfig({
          title: `Actualizar Información de Usuario "${datos.data.response_database.result[0].Username}"`,
          submitTitle: "Guardar información",
          formConfig: [
            {
              type: 4,
              required: true,
              value: datos.data.response_database.result[0].email,
              name: "email",
              label: "Ingrese el email del usuario",
              title: "Correo electrónico de usuario",
            },
            {
              type: 1,
              required: true,
              value: datos.data.response_database.result[0].given_name,
              name: "given_name",
              label: "Ingrese los nombres del usuario",
              title: "Nombres de usuario",
            },
            {
              type: 1,
              required: true,
              value: datos.data.response_database.result[0].family_name,
              name: "family_name",
              label: "Ingrese los apellidos del usuario",
              title: "Apellidos de usuario",
            },
            {
              type: 16,
              required: true,
              value: datos.data.response_database.result[0].gender,
              name: "gender",
              label: "Seleccione el género del usuario",
              title: "Género de Usuario",
              options: [
                { label: "FEMENINO", value: "F" },
                { label: "MASCULINO", value: "M" },
              ],
            },
            {
              type: 6,
              required: false,
              value: null,
              name: "picture",
              label: "Ingrese la foto del participante",
              title: "Foto del participante",
              accept: "image/png, image/gif, image/jpeg",
            },
            {
              type: 16,
              required: true,
              value: datos.data.response_database.result[0].permisos,
              name: "permisos",
              label: "Seleccione los permisos del usuario",
              title: "Permisos de Usuario",
              options: [
                { label: "ADMINISTRADOR", value: "ADMINISTRADOR" },
                { label: "DIRECCION-ACADEMICA", value: "DIRECCION-ACADEMICA" },
                { label: "CONTROL-ACADEMICO", value: "CONTROL-ACADEMICO" },
                { label: "CONTABILIDAD", value: "CONTABILIDAD" },
                { label: "DISEÑO", value: "DISEÑO" },
                { label: "COMERCIAL", value: "COMERCIAL" },
                { label: "REDES SOCIALES", value: "REDES SOCIALES" },
              ],
            },
          ],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.picture)
        .then((response_bucket) => {
          body.picture =
            response_bucket != null ? response_bucket.result.Location : null;
          auth_service
            .putDataAuth("/usr/" + id, jwt.TokenType, jwt.accessToken, body)
            .then((response_database) => {
              window.location.href = `/users/view/${id}`;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/users"}>
              Usuarios
            </Link>
            <Link underline="hover" color="inherit" href={`/users/view/${id}`}>
              {usr.given_name + " " + usr.family_name}
            </Link>
            <Typography color="text.primary">Actualizar Usuario</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UpdateUser;
