import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Button,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import PersonIcon from "@mui/icons-material/Person";

const ReadAsesores = () => {
  const { hash } = useParams();
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  useEffect(() => {}, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Asesores</Typography>
          </Breadcrumbs>
          <Button
            variant="contained"
            tabIndex={-1}
            startIcon={<PersonIcon />}
            href={`/asesor/create`}
            sx={{ width: { xs: "100%", md: "100%", lg: "35%" } }}
          >
            Registrar Asesor
          </Button>
          <Read
            config={{
              title: "Asesores registrados",
              endpoint: "/asesor",
              id: "UniqueID",
              onDelete: "/asesor",
              onView: `/asesor/view`,
              onUpdate: `/asesor/update`,
              admin: true,
              headers: [
                {
                  id: "usr",
                  numeric: false,
                  disablePadding: true,
                  label: "Usuario",
                },
                {
                  id: "Nombres",
                  numeric: false,
                  disablePadding: true,
                  label: "Nombres",
                },
                {
                  id: "Apellidos",
                  numeric: false,
                  disablePadding: true,
                  label: "Apellidos",
                },
                {
                  id: "hash",
                  numeric: false,
                  disablePadding: true,
                  label: "Llave pública",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadAsesores;
