import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDataUsr from "../../hooks/useDataUsr";
import {
  Box,
  Link,
  Breadcrumbs,
  CssBaseline,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import Formulario from "../../utils/forms/Formulario";
const admin_service = require("../../helpers/admin_service");

const DACA = () => {
  const { id } = useParams();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState([]);
  const { dataUsr, error, loading } = useDataUsr();
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view-asesor-comercial/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          admin_service
            .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
            .then((datos_docente) => {
              setFormConfig([
                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].tipo_institucion,
                  name: "tipo_institucion",
                  label: "Seleccione el tipo de institución",
                  title: "Tipo de Institución",
                  options: [
                    { label: "BANCO", value: "BANCO" },
                    { label: "FINANCIERA", value: "FINANCIERA" },
                    { label: "ASEGURADORA", value: "ASEGURADORA" },
                    { label: "COOPERATIVA", value: "COOPERATIVA" },
                    { label: "HOSPITAL", value: "HOSPITAL" },
                    { label: "COMERCIO", value: "COMERCIO" },
                    { label: "OTRO", value: "OTRO" },
                  ],
                },
                {
                  type: 1,
                  required: false,
                  value:
                    datos.data.response_database.result[0]
                      .tipo_institucion_otro,
                  name: "tipo_institucion_otro",
                  label:
                    'Especifique el tipo de institución si seleccionó "otro"',
                  title:
                    'Especifique el tipo de institución si seleccionó "otro"',
                },
                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].institucion,
                  name: "institucion",
                  label: "Ingrese el nombre de la institución",
                  title: "Nombre de la institución",
                },
                {
                  type: 16,
                  required: true,
                  value: datos.data.response_database.result[0].titulo,
                  name: "titulo",
                  label: "Seleccione el título del solicitante",
                  title: "Título del solicitante",
                  options: [
                    { label: "Licenciada", value: "Licenciada" },
                    { label: "Licenciado", value: "Licenciado" },
                    { label: "Magister", value: "Magister" },
                    { label: "Doctor", value: "Doctor" },
                    { label: "Otro", value: "Otro" },
                  ],
                },
                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].nombre,
                  name: "nombre",
                  label: "Ingrese el nombre del solicitante",
                  title: "Nombre del solicitante",
                },
                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].cargo,
                  name: "cargo",
                  label: "Ingrese el cargo del solicitante",
                  title: "Cargo del solicitante",
                },

                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].area_solicitud,
                  name: "area_solicitud",
                  label: "Ingrese el área que realiza la solicitud",
                  title: "Área que realiza la solicitud",
                },
                {
                  type: 5,
                  required: true,
                  value: datos.data.response_database.result[0].fecha_tentativa,
                  name: "fecha_tentativa",
                  label: "Fecha tentativa para el evento",
                  title: "Fecha tentativa para el evento",
                },
                {
                  type: 1,
                  required: true,
                  value:
                    datos.data.response_database.result[0].horario_tentativo,
                  name: "horario_tentativo",
                  label:
                    "por favor, colocar los rangos de horario que considere más factible para la actividad",
                  title: "Horario tentativo",
                },
                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].dias_requeridos,
                  name: "dias_requeridos",
                  label:
                    "por favor, colocar los indicar los días de su preferencia para realizar la actividad",
                  title: "Días requeridos",
                },
                {
                  type: 7,
                  required: true,
                  value: datos.data.response_database.result[0].cantidad_horas,
                  name: "cantidad_horas",
                  label:
                    "Ingrese la cantidad de horas disponibles para la capacitación",
                  title: "Cantidad de horas disponibles para la capacitación",
                },
                {
                  type: 17,
                  required: true,
                  value: datos.data.response_database.result[0].temas,
                  name: "temas",
                  title: "Temas a desarrollar",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "tema",
                      label: "Ingrese el nombre del tema",
                      title: "Nombre del tema",
                    },
                  ],
                },
                {
                  type: 16,
                  required: true,
                  value: datos.data.response_database.result[0].modalidad,
                  name: "modalidad",
                  label: "Seleccione la modalidad de preferencia",
                  title: "Modalidad de preferencia",
                  options: [
                    { label: "Presencial", value: "Presencial" },
                    { label: "Virtual", value: "Virtual" },
                    { label: "Híbrido", value: "Híbrido" },
                  ],
                },
                {
                  type: 7,
                  required: true,
                  value: datos.data.response_database.result[0].participantes,
                  name: "participantes",
                  label:
                    "Ingrese el no. de participantes (por favor ser lo más específico posible)",
                  title:
                    "No. de participantes (por favor ser lo más específico posible)",
                },
                {
                  type: 16,
                  required: true,
                  value: datos.data.response_database.result[0].nivel,
                  name: "nivel",
                  label:
                    "Seleccione el nivel de capacitación (enfoque que se requiere)",
                  title: "Nivel de capacitación (enfoque que se requiere)",
                  options: [
                    { label: "Principiante", value: "Principiante" },
                    { label: "Intermedio", value: "Intermedio" },
                    { label: "Avanzado", value: "Avanzado" },
                    { label: "Executive", value: "Executive" },
                  ],
                },
                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].requiere_diploma,
                  name: "requiere_diploma",
                  label: "Requiere diploma de participación impreso",
                  title: "Requiere diploma de participación impreso",
                  options: [
                    { label: "Si", value: "Si" },
                    { label: "No", value: "No" },
                  ],
                },
                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].requiere_certificado,
                  name: "requiere_certificado",
                  label: "Requiere certificado impreso",
                  title: "Requiere certificado impreso",
                  options: [
                    { label: "Si", value: "Si" },
                    { label: "No", value: "No" },
                  ],
                },
                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].requiere_material,
                  name: "requiere_material",
                  label: "Requiere material (presentaciones) impreso",
                  title: "Requiere material (presentaciones) impreso",
                  options: [
                    { label: "Si", value: "Si" },
                    { label: "No", value: "No" },
                  ],
                },
                {
                  type: 1,
                  required: true,
                  value: datos.data.response_database.result[0].perfil,
                  name: "perfil",
                  label: "Ingrese el perfil de los participantes",
                  title: "Perfil de los participantes",
                },
                {
                  type: 3,
                  required: false,
                  value: datos.data.response_database.result[0].docente,
                  name: "docente",
                  label: "Seleccione el docente",
                  title:
                    "Sugiere algún facilitador/docente (por favor, indicar)",
                  options: datos_docente.data.response.result.map((actual) => {
                    return {
                      label: `${actual.Nombres} ${actual.Apellidos}`,
                      value: actual.UniqueID,
                    };
                  }),
                },
                {
                  type: 8,
                  required: true,
                  value: datos.data.response_database.result[0].especificacion,
                  name: "especificacion",
                  label:
                    "Alguna especificación sobre la experiencia del facilitador (por favor especificar)",
                  title:
                    "Alguna especificación sobre la experiencia del facilitador (por favor especificar)",
                },
                {
                  type: 16,
                  required: true,
                  value: datos.data.response_database.result[0].requiere_equipo_computo,
                  name: "requiere_equipo_computo",
                  label: "Requiere equipo de cómputo",
                  title: "Requiere equipo de cómputo",
                  options: [
                    { label: "Si", value: "Si" },
                    { label: "No", value: "No" },
                  ],
                },
                {
                  type: 16,
                  required: true,
                  value: datos.data.response_database.result[0].protocolo,
                  name: "protocolo",
                  label:
                    "Posee un protocolo específico para la apertura de eventos",
                  title:
                    "Posee un protocolo específico para la apertura de eventos",
                  options: [
                    { label: "Si", value: "Si" },
                    { label: "No", value: "No" },
                  ],
                },
              ]);
              setCargando(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .putDataAuth(
          "/propuesta-in-house/" + id,
          jwt.TokenType,
          jwt.accessToken,
          { propuesta_asesor: body }
        )
        .then((response_db) => {
          window.location.href = `/in-house/view/${id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del equipo, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Inicio
          </Link>
          <Link underline="hover" color="inherit" href={"/in-house"}>
            Propuestas In House
          </Link>
          <Link underline="hover" color="inherit" href={"/in-house/view/" + id}>
            {id}
          </Link>
          <Typography color="text.primary">
            Llenar formato asesor comercial
          </Typography>
        </Breadcrumbs>
        <Box sx={{ width: "100%", marginTop: "75px" }}>
          <Formulario
            formConfig={{
              title: `Formato DACA`,
              submitTitle: "Guardar información",
              formConfig: formConfig,
            }}
            afterSubmit={afterSubmit}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default DACA;
