import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import CssBaseline from "@mui/material/CssBaseline";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import Box from "@mui/material/Box";
import Fab from "@mui/material/Fab";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import Fade from "@mui/material/Fade";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuIcon from "@mui/icons-material/Menu";
import Container from "@mui/material/Container";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import MenuItem from "@mui/material/MenuItem";
import { Outlet } from "react-router-dom";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";
import AccountBoxIcon from "@mui/icons-material/AccountBox";
import LogoutIcon from "@mui/icons-material/Logout";
import useDataUsr from "../../hooks/useDataUsr";
import Cookies from "js-cookie";

const auth_service = require("../../helpers/authService");
const settings = [
  { label: "Perfil", href: "" },
  { label: "Cerrar Sesión", href: "" },
];
function ScrollTop(props) {
  const { children, window } = props;
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    const anchor = (event.target.ownerDocument || document).querySelector(
      "#back-to-top-anchor"
    );

    if (anchor) {
      anchor.scrollIntoView({
        block: "center",
      });
    }
  };

  return (
    <Fade in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: "fixed", bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Fade>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  window: PropTypes.func,
};

export default function Layout(props) {
  const [anchorElNav, setAnchorElNav] = React.useState(null);
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const { dataUsr, error, loading } = useDataUsr();
  const [menu, setMenu] = useState([]);
  useEffect(() => {
    if (dataUsr) {
      if (dataUsr.permisos == "ADMINISTRADOR") {
        setMenu([
          {
            label: "Agendas Mensuales",
            href: process.env.REACT_APP_REDIRECT_ADMINISTRATIVO,
          },
          { label: "Formularios", href: process.env.REACT_APP_REDIRECT_FORMS },

          /* {
            label: "Eventos INHOUSE",
            href: process.env.REACT_APP_REDIRECT_INHOUSE,
          },
  
          {
            label: "Mails Masivos",
            href: process.env.REACT_APP_REDIRECT_MAILS,
          },*/
          /* {
            label: "Docentes",
            href: "/docente",
          },
          {
            label: "Oferta Formativa",
            href: "/catalogo-temas",
          },*/
          {
            label: "Docentes",
            href: "/docente",
          },
          { label: "Usuarios", href: "/users" },
          { label: "Asesores", href: "/asesor" },
          { label: "Help Desk", href: "/ticket-soporte" },
          { label: "Secretos", href: "/secrets" },
          { label: "Inventario IT", href: "/inventario-ti" },
          { label: "In House", href: "/in-house" },
        ]);
      } else if (dataUsr.permisos == "CONTROL-ACADEMICO") {
        setMenu([
          {
            label: "Agendas Mensuales",
            href: process.env.REACT_APP_REDIRECT_ADMINISTRATIVO,
          },
          { label: "Formularios", href: process.env.REACT_APP_REDIRECT_FORMS },
          { label: "Help Desk", href: "/ticket-soporte" },
          { label: "In House", href: "/in-house" },
        ]);
      } else if (
        dataUsr.permisos == "COMERCIAL" ||
        dataUsr.permisos == "REDES SOCIALES"
      ) {
        setMenu([
          { label: "Perfil Asesor Comercial", href: "/asesor-profile" },
          {
            label: "Agendas Mensuales",
            href: process.env.REACT_APP_REDIRECT_ADMINISTRATIVO,
          },
          { label: "Help Desk", href: "/ticket-soporte" },
          { label: "In House", href: "/in-house" },
        ]);
      } else if (dataUsr.permisos == "DIRECCION-ACADEMICA") {
        setMenu([
          {
            label: "Agendas Mensuales",
            href: process.env.REACT_APP_REDIRECT_ADMINISTRATIVO,
          },
          { label: "Help Desk", href: "/ticket-soporte" },
          { label: "In House", href: "/in-house" },
        ]);
      } else {
        setMenu([
          {
            label: "Agendas Mensuales",
            href: process.env.REACT_APP_REDIRECT_ADMINISTRATIVO,
          },
          { label: "Help Desk", href: "/ticket-soporte" },
        ]);
      }
    }
  }, [dataUsr]);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };
  function ElevationScroll(props) {
    const { children, window } = props;
    const trigger = useScrollTrigger({
      disableHysteresis: true,
      threshold: 0,
      target: window ? window() : undefined,
    });

    return React.cloneElement(children, {
      elevation: trigger ? 4 : 0,
    });
  }

  ElevationScroll.propTypes = {
    children: PropTypes.element.isRequired,
    window: PropTypes.func,
  };
  const handleLogout = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    auth_service
      .getDataAuth("/usr/logout", jwt.TokenType, jwt.refreshToken)
      .then((data) => {
        Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
        window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
      })
      .catch((err) => {
        console.log(err);
        Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
        window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
        console.log(err);
      });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <AppBar color="primary">
        <Container maxWidth="xxl">
          <Toolbar>
            <Box sx={{ flexGrow: 1 }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
                keepMounted
                transformOrigin={{
                  vertical: "top",
                  horizontal: "left",
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
              >
                {menu &&
                  menu.map((page, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        window.location.href = page.href;
                      }}
                    >
                      <ListItemText>{page.label}</ListItemText>
                    </MenuItem>
                  ))}
              </Menu>
            </Box>
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="/"
              sx={{
                mr: 2,
                display: "flex",
                flexGrow: 1,
                fontFamily: "monospace",
                fontWeight: 700,
                letterSpacing: ".3rem",
                color: "inherit",
                textDecoration: "none",
              }}
            >
              PORTAL EBG
            </Typography>

            {dataUsr != null && (
              <Box sx={{ flexGrow: 0 }}>
                <Tooltip title="Open settings">
                  <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
                    <Avatar alt={dataUsr.Username} src={dataUsr.picture} />
                  </IconButton>
                </Tooltip>
                <Menu
                  sx={{ mt: "45px" }}
                  id="menu-appbar"
                  anchorEl={anchorElUser}
                  anchorOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  keepMounted
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "right",
                  }}
                  open={Boolean(anchorElUser)}
                  onClose={handleCloseUserMenu}
                >
                  <MenuItem>
                    <ListItemText>{dataUsr.Username}</ListItemText>
                  </MenuItem>
                  {settings.map((setting, index) => (
                    <MenuItem
                      key={setting.label}
                      onClick={
                        index == 0
                          ? () => {
                              window.location.href = "/profile";
                            }
                          : handleLogout
                      }
                    >
                      <ListItemIcon>
                        {index == 0 && <AccountBoxIcon fontSize="small" />}
                        {index == 1 && <LogoutIcon fontSize="small" />}
                      </ListItemIcon>
                      <ListItemText>{setting.label}</ListItemText>
                    </MenuItem>
                  ))}
                </Menu>
              </Box>
            )}
          </Toolbar>
        </Container>
      </AppBar>
      <Toolbar id="back-to-top-anchor" />
      <Container maxWidth="xxl">
        <Outlet />
      </Container>
      <ScrollTop {...props}>
        <Fab size="small" aria-label="scroll back to top">
          <KeyboardArrowUpIcon />
        </Fab>
      </ScrollTop>
    </React.Fragment>
  );
}
