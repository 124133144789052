import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
import useDataUsr from "../../hooks/useDataUsr";
import { useParams } from "react-router-dom";
const moment = require("moment-timezone");
const admin_service = require("../../helpers/admin_service");
const UpdateTicket = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState(null);
  const { id } = useParams();
  const [ticket, setTicket] = useState({});
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/ticket-soporte/view/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setTicket(datos.data.response_database.result[0]);
          setFormConfig({
            title: `Actualizar información de ticket`,
            submitTitle: "Guardar información",
            formConfig: [
              {
                type: 16,
                required: true,
                value: datos.data.response_database.result[0].estado,
                name: "estado",
                label: "Seleccione el estado del ticket",
                title: "Estado del ticket",
                options: [
                  {
                    label: "NO ATENDIDO",
                    value: "NO ATENDIDO",
                  },
                  { label: "EN SEGUIMIENTO", value: "EN SEGUIMIENTO" },
                  { label: "RESUELTO", value: "RESUELTO" },
                  { label: "NO RESUELTO", value: "NO RESUELTO" },
                ],
              },
              {
                type: 16,
                required: true,
                value: datos.data.response_database.result[0].prioridad,
                name: "prioridad",
                label: "Seleccione la prioridad del ticket",
                title: "Estado del ticket",
                options: [
                  {
                    label: "BAJA",
                    value: "BAJA",
                  },
                  { label: "MEDIA", value: "MEDIA" },
                  { label: "ALTA", value: "ALTA" },
                ],
              },
              {
                type: 16,
                required: true,
                value: datos.data.response_database.result[0].categoria,
                name: "categoria",
                label: "Seleccione la categoria del ticket",
                title: "Categoría del ticket",
                options: [
                  {
                    label: "AGENDAS MENSUALES",
                    value: "AGENDAS MENSUALES",
                  },
                  { label: "CONGRESOS", value: "CONGRESOS" },
                  { label: "MÁQUINA ASIGNADA", value: "MÁQUINA ASIGNADA" },
                  { label: "SOPORTE TÉCNICO", value: "SOPORTE TÉCNICO" },
                ],
              },
            ],
          });
          setCargando(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      if (body.estado == "RESUELTO") {
        body.fecha_resolucion = moment(new Date())
          .tz("America/Guatemala")
          .format();
      }
      body.notification = ticket.estado == body.estado ? false : true;
      body.asunto = ticket.asunto;
      body.solicitante = ticket.solicitante;
      body.usr_email = ticket.usr_email;
      admin_service
        .putDataAuth(
          "/ticket-soporte/" + id,
          jwt.TokenType,
          jwt.accessToken,
          body
        )
        .then((response_database) => {
          window.location.href = `/ticket-soporte/view/${id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del ticket, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/ticket-soporte"}>
              Tickets Soporte IT
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={"/ticket-soporte/view/" + id}
            >
              {id}
            </Link>
            <Typography color="text.primary">Actualizar Ticket</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UpdateTicket;
