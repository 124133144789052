import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
const admin_service = require("../../helpers/admin_service");

const CreateSecret = () => {
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));

    setFormConfig({
      title: `Registrar secreto`,
      submitTitle: "Guardar información",
      formConfig: [
        {
          type: 1,
          required: true,
          value: "",
          name: "name",
          label: "Ingrese el nombre del secreto",
          title: "Nombres del secreto",
        },
        {
          type: 2,
          required: true,
          value: "",
          name: "secret",
          label: "Ingrese el secreto",
          title: "Secreto",
        },
      ],
    });
    setLoading(false);
  }, [contador]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .postDataAuth("/secrets", jwt.TokenType, jwt.accessToken, body)
        .then((response_database) => {
          window.location.href = `/secrets/view/${response_database.data.response_database.id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del asesor, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/secrets"}>
              Secretos
            </Link>
            <Typography color="text.primary">Registrar Secreto</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateSecret;
