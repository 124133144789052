import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
import Read from "../../utils/read/Read";
import useDataUsr from "../../hooks/useDataUsr";
const admin_service = require("../../helpers/admin_service");
const ViewTicket = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [cargando, setCargando] = useState(true);
  const [ticket, setTicket] = useState({});
  const { dataUsr, error, loading } = useDataUsr();
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/ticket-soporte/view/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setTicket(datos.data.response_database.result[0]);
        setCargando(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/ticket-soporte"}>
              Tickets Soporte IT
            </Link>
            <Typography color="text.primary">{ticket.id}</Typography>
          </Breadcrumbs>
          {dataUsr && (
            <View
              actionAfterDelete={() => {
                window.location.href = `/ticket-soporte`;
              }}
              configuration={{
                endpoint: "/ticket-soporte/view/" + id,
                onDelete:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? "/ticket-soporte"
                    : null,
                onUpdate:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? `/ticket-soporte/update/${id}`
                    : null,
                key: "id",
                withActions: true,
                confirmationMensage:
                  "¿Está segur@ de eliminar el ticket seleccionado?",
                name: "Ticket",
                admin: true,
                properties:
                  dataUsr.permisos == "ADMINISTRADOR"
                    ? [
                        {
                          label: "Identificador de Ticket",
                          field: "id",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Fecha de Registro",
                          field: "fecha_registro",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Última actualización",
                          field: "fecha_actualizacion",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Fecha de cierre del ticket",
                          field: "fecha_resolucion",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Asunto de Ticket",
                          field: "asunto",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Estado de ticket",
                          field: "estado",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Categoría de ticket",
                          field: "categoria",
                          type: 1,
                          icon: "image",
                        },
                        {
                          label: "Prioridad de ticket",
                          field: "prioridad",
                          type: 1,
                          icon: "image",
                        },
                        {
                          label: "Solicitante",
                          field: "solicitante",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Descripción de solicitud o problema",
                          field: "descripcion",
                          type: 7,
                          icon: "info",
                        },
                      ]
                    : [
                        {
                          label: "Identificador de Ticket",
                          field: "id",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Fecha de Registro",
                          field: "fecha_registro",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Última actualización",
                          field: "fecha_actualizacion",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Fecha de cierre del ticket",
                          field: "fecha_resolucion",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Asunto de Ticket",
                          field: "asunto",
                          type: 1,
                          icon: "info",
                        },

                        {
                          label: "Estado de ticket",
                          field: "estado",
                          type: 1,
                          icon: "image",
                        },
                        {
                          label: "Solicitante",
                          field: "solicitante",
                          type: 1,
                          icon: "info",
                        },
                        {
                          label: "Descripción de solicitud o problema",
                          field: "descripcion",
                          type: 7,
                          icon: "info",
                        },
                      ],
                extraActions:
                  ticket.estado != "RESUELTO"
                    ? [
                        {
                          configuration: {
                            type: 1,
                            icon: "info",
                            variant: "outline-primary",
                            title: "Registrar seguimiento",
                            url: `/ticket-soporte/seguimiento/${id}/create`,
                          },
                        },
                      ]
                    : [],
              }}
            />
          )}
          <Read
            config={{
              title: "Archivos adjuntos",
              endpoint: `/ticket-soporte/view-files/${id}`,
              onView: `/ticket-soporte/view-file/${id}`,
              id: "key",
              admin: true,
              headers: [
                {
                  id: "file",
                  numeric: false,
                  disablePadding: true,
                  label: "Archivo",
                },
              ],
            }}
          />
          <Read
            config={{
              title: "Bitácora de seguimiento",
              endpoint: `/seguimiento-ticket/${id}`,
              onView: `/ticket-soporte/seguimiento/${id}/view`,
              id: "id",
              admin: true,
              headers: [
                {
                  id: "fecha_registro",
                  numeric: false,
                  disablePadding: true,
                  label: "Fecha registro de seguimiento",
                },
                {
                  id: "usuario",
                  numeric: false,
                  disablePadding: true,
                  label: "Usuario",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewTicket;
