import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import Formulario from "../../utils/forms/Formulario";
const admin_service = require("../../helpers/admin_service");
const auth_service = require("../../helpers/authService");

const s3_service = require("../../helpers/s3_service");
const CreateAsesor = () => {
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    auth_service
      .getDataAuth("/usr", jwt.TokenType, jwt.accessToken)
      .then((response) => {
        setFormConfig({
          title: `Registrar asesor comercial`,
          submitTitle: "Guardar información",
          formConfig: [
            {
              type: 1,
              required: true,
              value: "",
              name: "Nombres",
              label: "Ingrese los nombres del asesor",
              title: "Nombres de asesor",
            },
            {
              type: 1,
              required: true,
              value: "",
              name: "Apellidos",
              label: "Ingrese los apellidos del asesor",
              title: "Apellidos de asesor",
            },

            {
              type: 6,
              required: false,
              value: null,
              name: "foto",
              label: "Ingrese la foto del asesor",
              title: "Foto del asesor",
              accept: "image/png, image/gif, image/jpeg",
            },
            {
              type: 4,
              required: false,
              value: "",
              name: "email",
              label: "Ingrese el email del asesor",
              title: "Correo electrónico de asesor",
            },
            {
              type: 1,
              required: false,
              value: "",
              name: "puesto",
              label: "Ingrese el puesto del asesor",
              title: "Puesto de asesor",
            },
            {
              type: 1,
              required: false,
              value: "",
              name: "whatsapp",
              label: "Ingrese el whatsapp del asesor",
              title: "Contacto de whatsapp",
            },
            {
              type: 4,
              required: false,
              value: "",
              name: "correo_institucion",
              label: "Ingrese el correo institucional",
              title: "Correo institucional",
            },
            {
              type: 1,
              required: false,
              value: "",
              name: "institucion",
              label: "Ingrese la institución",
              title: "Institución",
            },
            {
              type: 1,
              required: false,
              value: "",
              name: "telefono_institucion",
              label: "Ingrese el teléfono institucional",
              title: "Teléfono institucional",
            },
            {
              type: 6,
              required: false,
              value: null,
              name: "firma_mail",
              label: "Ingrese la firma de correo electrónico de asesor",
              title: "Firma de correo electrónico de asesor",
              accept: "image/png, image/gif, image/jpeg",
            },
            {
              type: 4,
              required: false,
              value: "",
              name: "email_transaccional",
              label: "Ingrese el correo transaccional de asesor",
              title: "Correo transaccional de asesor",
            },
            {
              type: 3,
              required: false,
              value: "",
              name: "cognito_sub",
              label: "Seleccione el acceso a portal de asesor",
              title: "Accesos",
              options: response.data.response.result.map((actual) => {
                return {
                  label: `${actual.given_name} ${actual.family_name}`,
                  value: actual.sub,
                };
              }),
            },
          ],
        });
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.foto)
        .then((response_bucket) => {
          body.foto =
            response_bucket != null ? response_bucket.result.Location : null;
          s3_service
            .saveFile(body.firma_mail)
            .then((response_bucket_2) => {
              body.firma_mail =
                response_bucket_2 != null
                  ? response_bucket_2.result.Location
                  : null;
              admin_service
                .postDataAuth("/asesor", jwt.TokenType, jwt.accessToken, body)
                .then((response_database) => {                  
                  window.location.href = `/asesor/view/${response_database.data.response_database.results[0].insertId}`;
                  resolve();
                })
                .catch((error) => {
                  console.log(error);
                  reject(
                    "ocurrido un error al guardar la información del asesor, intente de nuevo"
                  );
                });
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la imagen del asesor, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del asesor, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/asesor"}>
              Asesores
            </Link>

            <Typography color="text.primary">Registrar Asesor</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateAsesor;
