import React, { useState, useEffect } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import { Button, Typography, Grid } from "@mui/material";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import DeleteOutlineIcon from "@mui/icons-material/Delete";

import EditIcon from "@mui/icons-material/Edit";
import SettingsIcon from "@mui/icons-material/Settings";
import DeleteIcon from "@mui/icons-material/Delete";
import ManageHistoryIcon from "@mui/icons-material/ManageHistory";
import Modal from "@mui/material/Modal";
import Fade from "@mui/material/Fade";
import Backdrop from "@mui/material/Backdrop";
import Cookies from "js-cookie";
import Icons from "../icons/Icons";
const admin_service = require("../../helpers/admin_service");

const ActionBar = (props) => {
  const [open, setOpen] = React.useState(false);
  const [openAskModal, setOpenAskModal] = useState(false);
  const [cambios, setCambios] = useState(0);
  useEffect(() => {}, [cambios]);
  const onDelete = () => {
    setOpenAskModal(true);
    setCambios(cambios + 1);
  };

  const doDelete = () => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .deleteDataAuth(
        props.configuration.onDelete,
        jwt.TokenType,
        jwt.accessToken,
        {
          rows: [props.view[props.configuration.key]],
        }
      )
      .then((data) => {
        props.actionAfterDelete();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const toggleDrawer = (newOpen) => () => {
    setOpen(newOpen);
  };
  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    border: "2px solid #000",
    boxShadow: 24,
    p: 4,
  };
  const DrawerList = (
    <Box sx={{ width: 250 }} role="presentation" onClick={toggleDrawer(false)}>
      <List>
        {props.configuration.onUpdate != null && (
          <ListItem disablePadding>
            <ListItemButton href={props.configuration.onUpdate}>
              <ListItemIcon>
                <EditIcon />
              </ListItemIcon>
              <ListItemText primary="Editar" />
            </ListItemButton>
          </ListItem>
        )}
        {props.configuration.onDelete != null && (
          <ListItem disablePadding>
            <ListItemButton onClick={onDelete}>
              <ListItemIcon>
                <DeleteIcon />
              </ListItemIcon>
              <ListItemText primary="Eliminar" />
            </ListItemButton>
          </ListItem>
        )}
      </List>
      {props.configuration.extraActions &&
        props.configuration.extraActions.length > 0 && <Divider />}
      {props.configuration.extraActions &&
        props.configuration.extraActions.length > 0 && (
          <List>
            {props.configuration.extraActions.map((actual, index) => (
              <ListItem key={"extra-action-" + index} disablePadding>
                <ListItemButton
                  href={
                    actual.configuration.type == 1
                      ? actual.configuration.url
                      : null
                  }
                  onClick={
                    actual.configuration.type == 2
                      ? actual.configuration.action
                      : null
                  }
                >
                  <ListItemIcon>
                    <Icons icon={actual.configuration.icon} />
                  </ListItemIcon>
                  <ListItemText primary={actual.configuration.title} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
        )}
    </Box>
  );

  return (
    <div>
      <Button onClick={toggleDrawer(true)} startIcon={<SettingsIcon />}>
        click para ver las opciones
      </Button>
      <Drawer open={open} onClose={toggleDrawer(false)}>
        {DrawerList}
      </Drawer>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openAskModal}
        closeAfterTransition
        slots={{ backdrop: Backdrop }}
        slotProps={{
          backdrop: {
            timeout: 500,
          },
        }}
      >
        <Fade in={openAskModal}>
          <Box sx={style}>
            <Grid container spacing={2}>
              <Grid xs={12} md={12} lg={12}>
                <Typography
                  id="transition-modal-title"
                  variant="h6"
                  component="h2"
                >
                  SE NECESITA CONFIRMACIÓN
                </Typography>
              </Grid>
              <Grid xs={12} md={12} lg={12}>
                <Typography id="transition-modal-description" sx={{ mt: 2 }}>
                  {props.configuration.confirmationMensage}
                </Typography>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <Button
                  fullWidth
                  variant="contained"
                  tabIndex={-1}
                  startIcon={<DeleteOutlineIcon />}
                  color="error"
                  onClick={doDelete}
                >
                  Si
                </Button>
              </Grid>
              <Grid xs={6} md={6} lg={6}>
                <Button
                  tabIndex={-1}
                  color="primary"
                  fullWidth
                  onClick={() => {
                    setOpenAskModal(false);
                    setCambios(cambios + 1);
                  }}
                >
                  No
                </Button>
              </Grid>
            </Grid>
          </Box>
        </Fade>
      </Modal>
    </div>
  );
};

export default ActionBar;
