import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../../utils/forms/Formulario";
import useDataUsr from "../../../hooks/useDataUsr";
import { useParams } from "react-router-dom";
const admin_service = require("../../../helpers/admin_service");
const s3_service = require("../../../helpers/s3_service");
const CreateSeguimientoTicket = () => {
  const { id } = useParams();
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState(null);
  const [ticket, setTicket] = useState({});

  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/ticket-soporte/view/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setTicket(datos.data.response_database.result[0]);
          setFormConfig({
            title: `Registrar Seguimiento de Ticket`,
            submitTitle: "Guardar información",
            formConfig: [
              {
                type: 10,
                required: true,
                value: "",
                name: "descripcion",
                label: "Ingrese la descripción del seguimiento",
                title: "Descripción de seguimiento",
              },
              {
                type: 17,
                required: true,
                value: [],
                name: "archives",
                title: "Archivos adjuntos",
                fields: [
                  {
                    type: 6,
                    required: true,
                    value: null,
                    name: "archivo",
                    label: "Archivo adjunto",
                    title: "Archivo Adjunto",
                  },
                ],
              },
            ],
          });
          setCargando(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      body.ticket_id = id;
      body.usuario = `${dataUsr.given_name} ${dataUsr.family_name}`;
      body.usr_email = ticket.usr_email;
      body.asunto = ticket.asunto;
      body.estado = ticket.estado;
      body.solicitante = ticket.solicitante;
      if (body.archives.length > 0) {
        let archivos_adjuntos = [];
        body.archives.map((actual, index) => {
          s3_service
            .saveFile(actual[0])
            .then((response_bucket) => {
              if (response_bucket != null) {
                archivos_adjuntos.push({
                  file: response_bucket.result.Location,
                  key: response_bucket.result.Key,
                });
              }
              if (index == body.archives.length - 1) {
                body.archivos_adjuntos = archivos_adjuntos;
                admin_service
                  .postDataAuth(
                    "/seguimiento-ticket",
                    jwt.TokenType,
                    jwt.accessToken,
                    body
                  )
                  .then((response_database) => {
                    window.location.href = `/ticket-soporte/seguimiento/${id}/view/${response_database.data.response_database.id}`;
                    resolve();
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(
                      "ocurrido un error al guardar la información del seguimiento, intente de nuevo"
                    );
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar el archivo adjunto, intente de nuevo"
              );
            });
        });
      } else {
        admin_service
          .postDataAuth(
            "/seguimiento-ticket",
            jwt.TokenType,
            jwt.accessToken,
            body
          )
          .then((response_database) => {
            window.location.href = `/ticket-soporte/seguimiento/${id}/view/${response_database.data.response_database.id}`;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(
              "ocurrido un error al guardar la información del seguimiento, intente de nuevo"
            );
          });
      }
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/ticket-soporte"}>
              Tickets Soporte IT
            </Link>
            <Link
              underline="hover"
              color="inherit"
              href={"/ticket-soporte/view/" + id}
            >
              {id}
            </Link>
            <Typography color="text.primary">Registrar Seguimiento</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateSeguimientoTicket;
