import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
const admin_service = require("../../helpers/admin_service");
const ViewAsesor = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [asesor, setAsesor] = useState({});
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/asesor/view/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setAsesor(datos.data.response_database.result[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/asesor"}>
              Asesores
            </Link>
            <Typography color="text.primary">
              {asesor.Nombres + " " + asesor.Apellidos}
            </Typography>
          </Breadcrumbs>
          <View
            actionAfterDelete={() => {
              window.location.href = `/asesor`;
            }}
            configuration={{
              endpoint: "/asesor/view/" + id,
              onDelete: "/asesor",
              onUpdate: `/asesor/update/${id}`,
              key: "UniqueID",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar el asesor seleccionado?",
              name: "Asesor",
              admin: true,
              properties: [
                {
                  label: "Nombres",
                  field: "Nombres",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Apellidos",
                  field: "Apellidos",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Fotografía",
                  field: "foto",
                  type: 2,
                  icon: "image",
                },
                {
                  label: "Correo electrónico",
                  field: "email",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Puesto",
                  field: "puesto",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Contacto Whatsapp",
                  field: "whatsapp",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Correo institucional",
                  field: "correo_institucion",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Institución",
                  field: "institucion",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Teléfono institucional",
                  field: "telefono_institucion",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "firma de email",
                  field: "firma_mail",
                  type: 2,
                  icon: "image",
                },
                {
                  label: "email transaccional",
                  field: "email_transaccional",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Llave Pública",
                  field: "hash",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Cognito Sub",
                  field: "cognito_sub",
                  type: 1,
                  icon: "info",
                },
              ],
              /* extraActions: [
                {
                  configuration: {
                    type: 2,
                    icon: "key",
                    title: "Generar Contraseña Temporal",
                    action: setPwd,
                  },
                },
              ],*/
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewAsesor;
