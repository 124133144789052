import * as React from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
const Dashboard = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ bgcolor: "#cfe8fc" }}>
      </Box>
    </React.Fragment>
  );
};

export default Dashboard;
