import React, { useEffect, useState } from "react";
import {
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
  Button,
} from "@mui/material";
import Read from "../../utils/read/Read";
import CssBaseline from "@mui/material/CssBaseline";
import PersonIcon from "@mui/icons-material/Person";

const ReadDocente = () => {
  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Docentes</Typography>
          </Breadcrumbs>
          <Button
            variant="contained"
            tabIndex={-1}
            startIcon={<PersonIcon />}
            href={`/docente/create`}
            sx={{ width: { xs: "100%", md: "100%", lg: "35%" } }}
          >
            Registrar Docente
          </Button>
          <Read
            config={{
              title: "Docentes registrados",
              endpoint: "/docente",
              id: "UniqueID",
              onDelete: "/docente",
              onView: `/docente/view`,
              onUpdate: `/docente/update`,
              admin: true,
              headers: [
                {
                  id: "Nombres",
                  numeric: false,
                  disablePadding: true,
                  label: "Nombres",
                },
                {
                  id: "Apellidos",
                  numeric: false,
                  disablePadding: true,
                  label: "Apellidos",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ReadDocente;
