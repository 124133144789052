import Cookies from "js-cookie";
const auth_service = require("../helpers/authService");
export const useRefreshToken = () => {
  const refresh = async () => {
    try {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      auth_service
        .getDataAuth("/usr/refresh", jwt.TokenType, jwt.refreshToken)
        .then((data) => {
          Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
          Cookies.set(
            process.env.REACT_APP_COOKIE_KEY,
            JSON.stringify({
              TokenType: data.data.TokenType,
              accessToken: data.data.AccessToken,
              refreshToken: jwt.refreshToken,
            }),
            { domain: process.env.REACT_APP_DOMAIN }
          );
        })
        .catch((err) => {
          Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
          console.log(err);
          window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
        });
    } catch (error) {
      Cookies.remove(process.env.REACT_APP_COOKIE_KEY);
      console.log(error);
      window.location.href = process.env.REACT_APP_REDIRECT_ENDPOINT;
    }
  };
  return refresh;
};

export default useRefreshToken;
