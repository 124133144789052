import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
import ReadData from "../../utils/read/Read";
const admin_service = require("../../helpers/admin_service");
const ViewDocente = () => {
  const { id } = useParams();
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [docente, setDocente] = useState({});
  useEffect(() => {
    const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
    admin_service
      .getDataAuth("/docente/view/" + id, jwt.TokenType, jwt.accessToken)
      .then((datos) => {
        setDocente(datos.data.response_database.result[0]);
        setLoading(false);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [contador]);

  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/docente"}>
              Docentes
            </Link>
            <Typography color="text.primary">
              {docente.Nombres + " " + docente.Apellidos}
            </Typography>
          </Breadcrumbs>
          <View
            actionAfterDelete={() => {
              window.location.href = `/docente`;
            }}
            configuration={{
              endpoint: "/docente/view/" + id,
              onDelete: "/docente",
              onUpdate: `/docente/update/${id}`,
              key: "UniqueID",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar el docente seleccionado?",
              name: "Docente",
              admin: true,
              properties: [
                {
                  label: "Nombres",
                  field: "Nombres",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Apellidos",
                  field: "Apellidos",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Fotografía",
                  field: "Foto",
                  type: 2,
                  icon: "image",
                },
                {
                  label: "Correo electrónico",
                  field: "Correo",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Telefono",
                  field: "Telefono",
                  type: 1,
                  icon: "info",
                },
              ],
            }}
          />

          <ReadData
            config={{
              title: "Experiencia Docente",
              endpoint: "/docente/get-exp/" + id,
              id: "UniqueID",
              admin: true,
              headers: [
                {
                  id: "descripcion",
                  numeric: false,
                  disablePadding: true,
                  label: "Descripcion",
                },
                {
                  id: "Nombre",
                  numeric: false,
                  disablePadding: true,
                  label: "Tipo Experiencia",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewDocente;
