import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Box,
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
} from "@mui/material";
import useDataUsr from "../../hooks/useDataUsr";
import View from "../../utils/view/View";
import ReadData from "../../utils/read/Read";
import Formulario from "../../utils/forms/Formulario";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckIcon from "@mui/icons-material/Check";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import TerminalIcon from "@mui/icons-material/Terminal";
import LocalPrintshopIcon from "@mui/icons-material/LocalPrintshop";
import InfoIcon from "@mui/icons-material/Info";
const admin_service = require("../../helpers/admin_service");

const FormatoFacilitador = (props) => {
  const [formato, setFormato] = useState(null);
  const [cargando, setCargando] = useState(true);
  const { dataUsr, error, loading } = useDataUsr();
  const [formConfig, setFormConfig] = useState(null);
  const [propuesta, setPropuesta] = useState(null);
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view-facilitador/" + props.id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          admin_service
            .getDataAuth(
              "/propuesta-in-house/view/" + props.id,
              jwt.TokenType,
              jwt.accessToken
            )
            .then((datos_propuesta) => {
              admin_service
                .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
                .then((datos_docentes) => {
                  setFormato(datos.data.response_database.result[0]);
                  setCargando(false);
                  setPropuesta(
                    datos_propuesta.data.response_database.result[0]
                  );
                  setFormConfig([
                    {
                      type: 3,
                      required: false,
                      value:
                        datos_propuesta.data.response_database.result[0]
                          .propuesta_asesor.docente,
                      name: "docente",
                      label: "Seleccione el docente",
                      title:
                        "Seleccione el docente al cual enviar la propuesta",
                      options: datos_docentes.data.response.result.map(
                        (actual) => {
                          return {
                            label: `${actual.Nombres} ${actual.Apellidos}`,
                            value: actual.UniqueID,
                          };
                        }
                      ),
                    },
                  ]);
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);

  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .postDataAuth(
          "/propuesta-in-house/send-docente-notification/" + props.id,
          jwt.TokenType,
          jwt.accessToken,
          body
        )
        .then((response_db) => {
          window.location.href = `/in-house/view/${props.id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del equipo, intente de nuevo"
          );
        });
    });
  };
  return (
    <Box>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {!formato ? (
        <Box>
          <Stack spacing={5}>
            <Typography textAlign="center" variant="h4">
              Formato de facilitador no generado
            </Typography>
            {dataUsr &&
              dataUsr.permisos == "CONTROL-ACADEMICO" &&
              propuesta != null &&
              propuesta.propuesta_asesor != null && (
                <Formulario
                  formConfig={{
                    title: `Enviar formato DAFAC a docente`,
                    submitTitle: "Guardar información",
                    formConfig: formConfig,
                  }}
                  afterSubmit={afterSubmit}
                />
              )}

            {dataUsr &&
              dataUsr.permisos == "CONTROL-ACADEMICO" &&
              propuesta != null &&
              propuesta.propuesta_asesor == null && (
                <Typography variant="h5" textAlign="center">
                  Debe llenarse primero el formato de asesor comercial para
                  generar formato de facilitador
                </Typography>
              )}
          </Stack>
        </Box>
      ) : (
        <Box>
          <Stack spacing={5}>
            {" "}
            <View
              actionAfterDelete={() => {
                window.location.href = `/in-house`;
              }}
              configuration={{
                endpoint: "/propuesta-in-house/view-facilitador/" + props.id,
                key: "id",
                withActions: false,
                name: "INFORMACIÓN GENERAL",
                admin: true,
                properties: [
                  {
                    label: "Título de la capacitación",
                    field: "titulo",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Descripción de la propuesta",
                    field: "descripcion",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Objetivo general",
                    field: "objetivo_general",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Propuesta metodologica",
                    field: "propuesta_metodologica",
                    type: 1,
                    icon: "info",
                  },
                  {
                    label: "Observaciones adicionales",
                    field: "observaciones_adicionales",
                    type: 1,
                    icon: "info",
                  },
                ],
              }}
            />
            {formato != null &&
              formato.objetivos_especificos != null &&
              formato.objetivos_especificos.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Objetivos Específicos</Typography>
                    <List>
                      {formato.objetivos_especificos.map((actual) => (
                        <ListItem disablePadding>
                          <ListItemButton>
                            <ListItemIcon>
                              <CheckIcon />
                            </ListItemIcon>
                            <ListItemText primary={actual[0]} />
                          </ListItemButton>
                        </ListItem>
                      ))}
                    </List>
                  </Stack>
                </Box>
              )}
            {formato != null &&
              formato.sesiones != null &&
              formato.sesiones.length > 0 && (
                <Box>
                  <Stack spacing={3}>
                    <Typography variant="h3">Contenido de sesiones</Typography>
                    {formato.sesiones.map((actual, index) => (
                      <Accordion>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          Sesión #{index + 1}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography variant="h5">
                            Detalles de sesion
                          </Typography>
                          <List>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <AccessTimeIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Cantidad de horas"
                                  secondary={actual.master[0]}
                                />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <TerminalIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Requiere software especializado"
                                  secondary={actual.master[1]}
                                />
                              </ListItemButton>
                            </ListItem>
                            <ListItem>
                              <ListItemButton>
                                <ListItemIcon>
                                  <LocalPrintshopIcon />
                                </ListItemIcon>
                                <ListItemText
                                  primary="Requiere material impreso"
                                  secondary={actual.master[2]}
                                />
                              </ListItemButton>
                            </ListItem>
                          </List>
                          <Typography variant="h5">
                            Temas a desarrollar
                          </Typography>
                          <List>
                            {actual.detail.map((current) => (
                              <ListItem disablePadding>
                                <ListItemButton>
                                  <ListItemIcon>
                                    <InfoIcon />
                                  </ListItemIcon>
                                  <ListItemText primary={current[0]} />
                                </ListItemButton>
                              </ListItem>
                            ))}
                          </List>
                        </AccordionDetails>
                      </Accordion>
                    ))}
                  </Stack>
                </Box>
              )}
            {formato.docente != null && formato.docente != "" && (
              <View
                actionAfterDelete={() => {
                  window.location.href = `/docente`;
                }}
                configuration={{
                  endpoint: "/docente/view/" + formato.docente,
                  key: "UniqueID",
                  withActions: false,
                  name: "Docente Asignado",
                  admin: true,
                  properties: [
                    {
                      label: "Nombres",
                      field: "Nombres",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Apellidos",
                      field: "Apellidos",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Fotografía",
                      field: "Foto",
                      type: 2,
                      icon: "image",
                    },
                    {
                      label: "Correo electrónico",
                      field: "Correo",
                      type: 1,
                      icon: "info",
                    },
                    {
                      label: "Telefono",
                      field: "Telefono",
                      type: 1,
                      icon: "info",
                    },
                  ],
                }}
              />
            )}
            {formato.docente != null && formato.docente != "" && (
              <ReadData
                config={{
                  title: "Experiencia Docente",
                  endpoint: "/docente/get-exp/" + formato.docente,
                  id: "UniqueID",
                  admin: true,
                  headers: [
                    {
                      id: "descripcion",
                      numeric: false,
                      disablePadding: true,
                      label: "Descripcion",
                    },
                    {
                      id: "Nombre",
                      numeric: false,
                      disablePadding: true,
                      label: "Tipo Experiencia",
                    },
                  ],
                }}
              />
            )}
          </Stack>
        </Box>
      )}
    </Box>
  );
};

export default FormatoFacilitador;
