import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
import useDataUsr from "../../hooks/useDataUsr";
const auth_service = require("../../helpers/authService");
const s3_service = require("../../helpers/s3_service");

const UpdateProfile = () => {
  const [contador] = useState(0);
  const [loading, setLoading] = useState(true);
  const [formConfig, setFormConfig] = useState(null);
  const { dataUsr, error, cargando } = useDataUsr();

  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      auth_service
        .getDataAuth("/usr/view/" + dataUsr.sub, jwt.TokenType, jwt.accessToken)
        .then((datos) => {
          setFormConfig({
            title: `Actualizar Perfil de Usuario "${datos.data.response_database.result[0].Username}"`,
            submitTitle: "Guardar información",
            formConfig: [
              {
                type: 6,
                required: false,
                value: null,
                name: "picture",
                label: "Ingrese la foto de perfil",
                title: "Foto de perfil",
                accept: "image/png, image/gif, image/jpeg",
              },
            ],
          });
          setLoading(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [contador, dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      s3_service
        .saveFile(body.picture)
        .then((response_bucket) => {
          body.picture =
            response_bucket != null ? response_bucket.result.Location : null;
          auth_service
            .putDataAuth(
              "/usr/" + dataUsr.sub,
              jwt.TokenType,
              jwt.accessToken,
              body
            )
            .then((response_database) => {
              window.location.href = `/profile`;
              resolve();
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
              );
            });
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la imagen del usuario, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Typography color="text.primary">Actualizar Perfil</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default UpdateProfile;
