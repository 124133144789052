import React, { useState, useEffect } from "react";
import CssBaseline from "@mui/material/CssBaseline";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import { Grid, Stack } from "@mui/material";
import Formulario from "../../utils/forms/Formulario";
import Cookies from "js-cookie";
import useDataUsr from "../../hooks/useDataUsr";

const auth_service = require("../../helpers/authService");
export default function Authenticate() {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  useEffect(() => {
    if (dataUsr && cargando) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      auth_service
        .postDataAuth("/usr/send-hard-token", jwt.TokenType, jwt.accessToken, {
          usuario: dataUsr.Username,
        })
        .then((data) => {
          setCargando(false);
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [dataUsr]);

  const afterToken = (body) => {
    return new Promise((resolve, reject) => {
      auth_service
        .postData("/usr/validate-hard-token", {
          username: dataUsr.Username,
          mfa: body.mfa,
        })
        .then((response) => {
          window.location.href = "/secrets";
        })
        .catch((error) => {
          console.log(error);
          reject({
            msg: `Esto pudo ocurrir por los siguientes motivos:
              <ul>                
                <li>Ingreso erróneo de token MFA</li>
                <li>Ingreso de token MFA expirado</li>
              </ul>`,
          });
        });
    });
  };

  return (
    <React.Fragment>
      <CssBaseline />
      <Container
        maxWidth="xxl"
        style={{
          backgroundImage:
            "url(https://test-escuelabancaria.s3.us-east-2.amazonaws.com/Medios+General/Logotipos+Finales+ABG+2022-01.png)",
          backgroundSize: "25vh",
          backgroundRepeat: "no-repeat",
          backgroundPosition: "top center",
        }}
      >
        <Box
          sx={{
            height: "100vh",
            display: "flex",
            alignItems: "center",
          }}
        >
          <Grid container justifyContent="center" style={{ width: "100%" }}>
            <Stack
              spacing={5}
              style={{
                width: "75%",
                border: "5px solid #1e3d52",
                borderRadius: "30px",
                padding: "1%",
              }}
            >
              <Formulario
                afterSubmit={afterToken}
                formConfig={{
                  title: "",
                  submitTitle: "Continuar",
                  formConfig: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "mfa",
                      label:
                        "Ingrese el token que ha llegado a su bandeja de e-mail",
                      title: "Token",
                    },
                  ],
                }}
              />
            </Stack>
          </Grid>
        </Box>
      </Container>
    </React.Fragment>
  );
}
