import React from "react";
import { Typography, Stack, Breadcrumbs, Link, Box } from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import { useParams } from "react-router-dom";
import View from "../../utils/view/View";
import Read from "../../utils/read/Read";
const ViewInventarioTI = () => {
  const { id } = useParams();

  return (
    <React.Fragment>
      <CssBaseline />
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/inventario-ti"}>
              Inventario IT
            </Link>
            <Typography color="text.primary">{id}</Typography>
          </Breadcrumbs>

          <View
            actionAfterDelete={() => {
              window.location.href = `/inventario-ti`;
            }}
            configuration={{
              endpoint: "/inventario-ti/view/" + id,
              onDelete: "/inventario-ti",
              onUpdate: `/inventario-ti/update/${id}`,
              key: "id",
              withActions: true,
              confirmationMensage:
                "¿Está segur@ de eliminar el equipo seleccionado?",
              name: "Equipo",
              admin: true,
              properties: [
                {
                  label: "Identificador de equipo",
                  field: "id",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Fecha de Registro",
                  field: "fecha_registro",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Última actualización",
                  field: "fecha_actualizacion",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Descripción del equipo",
                  field: "descripcion",
                  type: 7,
                  icon: "info",
                },
                {
                  label: "Código serial",
                  field: "serial",
                  type: 1,
                  icon: "info",
                },
                {
                  label: "Fecha de vencimiento de garantía",
                  field: "vencimiento_garantia",
                  type: 1,
                  icon: "info",
                },
              ],
              extraActions: [
                {
                  configuration: {
                    type: 1,
                    icon: "save",
                    title: "Subir imagen o documentacion",
                    url: `/inventario-ti/upload-files/${id}`,
                  },
                },
              ],
            }}
          />

          <Read
            config={{
              title: "Archivos adjuntos",
              endpoint: `/fotografias-inventario-ti/read/${id}`,
              onView: `/inventario-ti/view-file/${id}`,
              onDelete: `/fotografias-inventario-ti`,
              id: "id",
              admin: true,
              headers: [
                {
                  id: "descripcion",
                  numeric: false,
                  disablePadding: true,
                  label: "Descripcion Fotografia o Documentacion",
                },
                {
                  id: "url",
                  numeric: false,
                  disablePadding: true,
                  label: "Url",
                },
              ],
            }}
          />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default ViewInventarioTI;
