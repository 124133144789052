import { useEffect } from "react";
import React from "react";
import { useParams } from "react-router-dom";
const s3_service = require("../../../helpers/s3_service");
const ReadFileSeguimiento = () => {
  const { ticket_id, id, url } = useParams();
  useEffect(() => {
    s3_service
      .getSignedUrl(url)
      .then((signed_url) => {
        window.open(signed_url, "_blank");
        window.location.href = `/ticket-soporte/seguimiento/${ticket_id}/view/${id}`;
      })
      .catch((error) => {
        console.log(error);
      });
  });
  return <div></div>;
};

export default ReadFileSeguimiento;
