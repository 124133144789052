import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import useDataUsr from "../../hooks/useDataUsr";
import {
  Box,
  Link,
  Breadcrumbs,
  CssBaseline,
  Typography,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import Cookies from "js-cookie";
import Formulario from "../../utils/forms/Formulario";
const admin_service = require("../../helpers/admin_service");

const IntegracionTecnicoEcono = () => {
  const { id } = useParams();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState([]);
  const { dataUsr, error, loading } = useDataUsr();
  const [propuesta, setPropuesta] = useState(null);
  useEffect(() => {
    if (dataUsr) {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .getDataAuth(
          "/propuesta-in-house/view/" + id,
          jwt.TokenType,
          jwt.accessToken
        )
        .then((datos) => {
          setPropuesta(datos.data.response_database.result[0]);
          admin_service
            .getDataAuth("/docente", jwt.TokenType, jwt.accessToken)
            .then((datos_docente) => {
              setFormConfig([
                {
                  type: 3,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .docente,
                  name: "docente",
                  label: "Seleccione el docente",
                  title: "Docente",
                  options: datos_docente.data.response.result.map((actual) => {
                    return {
                      label: `${actual.Nombres} ${actual.Apellidos}`,
                      value: actual.UniqueID,
                    };
                  }),
                },

                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_asesor
                      .docente == null ||
                    datos.data.response_database.result[0].propuesta_asesor
                      .docente == ""
                      ? "ASESOR COMERCIAL NO RECOMENDÓ FACILITADOR"
                      : datos.data.response_database.result[0].propuesta_asesor
                          .docente ==
                        datos.data.response_database.result[0]
                          .propuesta_facilitador.docente
                      ? "SÍ"
                      : "NO",
                  name: "mismo_facilitador",
                  label:
                    "¿Es el mismo facilitador propuesto por el asesor comercial?",
                  title:
                    "¿Es el mismo facilitador propuesto por el asesor comercial?",
                  options: [
                    { label: "SÍ", value: "SÍ" },
                    { label: "NO", value: "NO" },
                    {
                      label: "ASESOR COMERCIAL NO RECOMENDÓ FACILITADOR",
                      value: "ASESOR COMERCIAL NO RECOMENDÓ FACILITADOR",
                    },
                  ],
                },
                {
                  type: 8,
                  required: false,
                  value: "",
                  name: "motivo_no_recomendacion",
                  label:
                    "Si la respuesta es No, indicar el porqué no está en la propuesta",
                  title:
                    "Si la respuesta es No, indicar el porqué no está en la propuesta",
                },
                {
                  type: 16,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_cliente
                      .tipo_actividad,
                  name: "tipo_actividad",
                  label: "Seleccione el tipo de actividad que se requiere",
                  title: "Tipo de actividad que se requiere",
                  options: [
                    { label: "Curso", value: "Curso" },
                    { label: "Taller", value: "Taller" },
                    { label: "Curso-Taller", value: "Curso-Taller" },
                    { label: "Seminario", value: "Seminario" },
                    { label: "Diplomado", value: "Diplomado" },
                    { label: "Certificación", value: "Certificación" },
                    { label: "Conferencia", value: "Conferencia" },
                  ],
                },
                {
                  type: 1,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .titulo,
                  name: "titulo",
                  label: "Ingrese el título de la capacitación",
                  title: "Título de la capacitación",
                },
                {
                  type: 7,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_asesor
                      .cantidad_horas,
                  name: "cantidad_horas",
                  label: "Ingrese la cantidad de horas para la capacitación",
                  title: "Cantidad de horas para la capacitación",
                },
                {
                  type: 17,
                  required: true,
                  value: [],
                  name: "competencias_genericas",
                  title: "Competencias genéricas a desarrollar",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "competencia",
                      label: "Ingrese el nombre de la competencia",
                      title: "Nombre de la competencia",
                    },
                  ],
                },
                {
                  type: 17,
                  required: true,
                  value: [],
                  name: "competencias_transversales",
                  title: "Competencias transversales a desarrollar",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "competencia",
                      label: "Ingrese el nombre de la competencia",
                      title: "Nombre de la competencia",
                    },
                  ],
                },
                {
                  type: 17,
                  required: true,
                  value: [],
                  name: "competencias_tecnicas",
                  title: "Competencias técnicas a desarrollar",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "competencia",
                      label: "Ingrese el nombre de la competencia",
                      title: "Nombre de la competencia",
                    },
                  ],
                },
                {
                  type: 1,
                  required: true,
                  value: "",
                  name: "dirigido_a",
                  label: "Ingrese dirigido a",
                  title: "Dirigido a",
                },
                {
                  type: 7,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_asesor
                      .participantes,
                  name: "participantes",
                  label: "Ingrese el no. de participantes",
                  title: "No. de participantes",
                },
                {
                  type: 1,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .objetivo_general,
                  name: "objetivo_general",
                  label: "Ingrese el objetivo general",
                  title: "Objetivo general",
                },
                {
                  type: 17,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .objetivos_especificos,
                  name: "objetivos_especificos",
                  title: "Objetivos específicos",
                  fields: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "objetivo",
                      label: "Ingrese el nombre del objetivo",
                      title: "Nombre del objetivo",
                    },
                  ],
                },
                {
                  type: 8,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .propuesta_metodologica,
                  name: "propuesta_metodologica",
                  label: "Propuesta metodológica",
                  title: "Propuesta metodológica",
                },
                {
                  type: 18,
                  required: true,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .sesiones,
                  name: "sesiones",
                  title: "Contenido de sesiones",
                  master: [
                    {
                      type: 7,
                      required: true,
                      value: "",
                      name: "cantidad_horas",
                      label: "Ingrese la cantidad de horas",
                      title: "Cantidad de horas",
                    },
                    {
                      type: 1,
                      required: false,
                      value: "",
                      name: "requiere_software",
                      label: "Colocar nombre y version",
                      title:
                        "Especificar si requiere algún software especializado",
                    },
                    {
                      type: 1,
                      required: false,
                      value: "",
                      name: "requiere_material_impreso",
                      label:
                        "Especificar si requiere algún material impreso (evento presencial)",
                      title:
                        "Especificar si requiere algún material impreso (evento presencial)",
                    },
                  ],
                  detail: [
                    {
                      type: 1,
                      required: true,
                      value: "",
                      name: "tema",
                      label: "Ingrese el tema a desarrollar",
                      title: "Tema a desarrollar",
                    },
                  ],
                },
                {
                  type: 8,
                  required: false,
                  value:
                    datos.data.response_database.result[0].propuesta_facilitador
                      .observaciones_adicionales,
                  name: "observaciones_adicionales",
                  label: "Ingresa las observaciones adicionales",
                  title: "Observaciones Adicionales",
                },
                {
                  type: 16,
                  required: true,
                  value: "",
                  name: "moneda",
                  label: "Seleccione la moneda para pagar honorarios",
                  title: "Moneda para pagar honorarios",
                  options: [
                    { label: "GTQ Q", value: "GTQ Q" },
                    { label: "USD $", value: "USD $" },
                  ],
                },
                {
                  type: 7,
                  required: true,
                  value: 0,
                  name: "honorarios",
                  label: "Honorarios por hora pactados",
                  title: "Honorarios por hora pactados",
                },
                {
                  type: 7,
                  required: true,
                  value: 0,
                  name: "horas_total",
                  label: "Cantidad total de horas (para cálculo de honorarios)",
                  title: "Cantidad total de horas (para cálculo de honorarios)",
                },
                {
                  type: 7,
                  required: true,
                  value: 0,
                  name: "porcentaje_ganancia",
                  label: "Porcentaje de ganancia",
                  title: "Porcentaje de ganancia",
                },
              ]);
              setCargando(false);
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      admin_service
        .putDataAuth(
          "/propuesta-in-house/" + id,
          jwt.TokenType,
          jwt.accessToken,
          { propuesta_tecnica_economica: body }
        )
        .then((response_db) => {
          window.location.href = `/in-house/view/${id}`;
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject(
            "ocurrido un error al guardar la información del equipo, intente de nuevo"
          );
        });
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Breadcrumbs aria-label="breadcrumb">
          <Link underline="hover" color="inherit" href="/">
            Inicio
          </Link>
          <Link underline="hover" color="inherit" href={"/in-house"}>
            Propuestas In House
          </Link>
          <Link underline="hover" color="inherit" href={"/in-house/view/" + id}>
            {id}
          </Link>
          <Typography color="text.primary">
            Llenar propuesta técnica económica
          </Typography>
        </Breadcrumbs>
        <Box sx={{ width: "100%", marginTop: "75px" }}>
          <Formulario
            formConfig={{
              title: `Integración técnica económica`,
              submitTitle: "Guardar información",
              formConfig: formConfig,
            }}
            afterSubmit={afterSubmit}
          />
        </Box>
      </Box>
    </React.Fragment>
  );
};

export default IntegracionTecnicoEcono;
