var AWS = require("aws-sdk");
const { v4 } = require("uuid");
var Buffer = require("buffer/").Buffer;
var saveFile = async (base64File) => {
  return new Promise(async (resolve, reject) => {
    if (base64File == null) {
      resolve(null);
    } else {
      try {
        AWS.config.update({
          region: process.env.REACT_APP_AWS_REGION,
          accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
          secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
        });
        const base64Code = base64File.split(";");
        let base64FileCode = base64Code[1].split(",")[1];
        let fileType = base64Code[0].split(":")[1];
        let extention = "." + base64Code[0].split(":")[1].split("/")[1];
        var fileName = v4() + extention;
        let buff = new Buffer.from(base64FileCode, "base64");
        var s3 = new AWS.S3();
        const params = {
          Bucket: process.env.REACT_APP_BCKT,
          Key: fileName,
          Body: buff,
          ContentType: fileType,
        };
        const putResult = await s3.upload(params).promise();
        resolve({ ret: 1, msg: "ok", result: putResult, status: true });
      } catch (error) {
        console.log(error);
        reject({ ret: 0, msg: error });
      }
    }
  });
};
var saveFileNamed = async (base64File, name) => {
  return new Promise(async (resolve, reject) => {
    try {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      });
      const base64Code = base64File.split(";");
      let base64FileCode = base64Code[1].split(",")[1];
      let fileType = base64Code[0].split(":")[1];
      let extention = "." + base64Code[0].split(":")[1].split("/")[1];
      var fileName = name + extention;
      let buff = new Buffer.from(base64FileCode, "base64");
      var s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_BCKT,
        Key: fileName,
        Body: buff,
        ContentType: fileType,
      };
      const putResult = await s3.upload(params).promise();
      resolve({ ret: 1, msg: "ok", result: putResult, status: true });
    } catch (error) {
      console.log(error);
      reject({ ret: 0, msg: error });
    }
  });
};
const getObject = async (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      });
      var s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_BCKT,
        Key: key,
      };
      const getResult = await s3.getObject(params).promise();
      const salida = `data:${getResult.ContentType};base64,${Buffer.from(
        getResult.Body
      ).toString("base64")}`;
      resolve({
        ret: 1,
        msg: "ok",
        result: getResult,
        salida: salida,
        status: true,
      });
    } catch (error) {
      console.log(error);
      reject({ ret: 0, msg: error });
    }
  });
};
const getSignedUrl = async (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      AWS.config.update({
        region: process.env.REACT_APP_AWS_REGION,
        accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_AWS_SECRET_KEY,
      });
      var s3 = new AWS.S3();
      const params = {
        Bucket: process.env.REACT_APP_BCKT,
        Key: key,
      };
      const signedUrl = await s3.getSignedUrlPromise("getObject", params);
      resolve(signedUrl);
    } catch (error) {
      console.log(error);
      reject({ ret: 0, msg: error });
    }
  });
};

const downloadObject = (key) => {
  return new Promise(async (resolve, reject) => {
    try {
      getObject(key)
        .then((response) => {
          const base64Code = response.salida.split(";");
          let base64FileCode =
            base64Code.length == 2
              ? base64Code[1].split(",")[1]
              : base64Code[2].split(",")[1];
          let fileType = base64Code[0].split(":")[1];
          let extention = "." + key.split(".")[key.split(".").length - 1];
          if (extention == ".sheet") {
            extention = ".xlsx";
          } else if (extention == ".document") {
            extention = ".docx";
          } else if (extention == ".presentation") {
            extention = ".pptx";
          }
          var fileName = v4() + extention;
          const byteCharacters = atob(base64FileCode);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          const blob = new Blob([byteArray], {
            type: fileType,
          });
          const link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileName;
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
          resolve();
        })
        .catch((error) => {
          console.log(error);
          reject({ ret: 0, msg: error });
        });
    } catch (error) {
      console.log(error);
      reject({ ret: 0, msg: error });
    }
  });
};
module.exports = {
  saveFile,
  getObject,
  downloadObject,
  getSignedUrl,
  saveFileNamed,
};
