import React, { useEffect, useState } from "react";
import Cookies from "js-cookie";
import {
  Backdrop,
  CircularProgress,
  Typography,
  Stack,
  Breadcrumbs,
  Link,
  Box,
} from "@mui/material";
import CssBaseline from "@mui/material/CssBaseline";
import Formulario from "../../utils/forms/Formulario";
import useDataUsr from "../../hooks/useDataUsr";
const admin_service = require("../../helpers/admin_service");
const s3_service = require("../../helpers/s3_service");
const CreateInventarioTI = () => {
  const { dataUsr, error, loading } = useDataUsr();
  const [cargando, setCargando] = useState(true);
  const [formConfig, setFormConfig] = useState(null);

  useEffect(() => {
    if (dataUsr) {
      setFormConfig({
        title: `Registrar Equipo`,
        submitTitle: "Guardar información",
        formConfig: [
          {
            type: 10,
            required: true,
            value: "",
            name: "descripcion",
            label: "Ingrese la descripción del equipo",
            title: "Descripción de equipo",
          },
          {
            type: 1,
            required: true,
            value: "",
            name: "serial",
            label: "Ingrese el código serial del equipo",
            title: "Código serial de equipo",
          },
          {
            type: 5,
            required: true,
            value: "",
            name: "vencimiento_garantia",
            label: "Fecha de vencimiento de garantía de equipo",
            title: "Fecha de vencimiento de garantía de equipo",
          },
          {
            type: 17,
            required: true,
            value: [],
            name: "archives",
            title: "Archivos adjuntos",
            fields: [
              {
                type: 1,
                required: true,
                value: "",
                name: "descripcion",
                label:
                  "Ingrese la descripción de la fotografía o documentación",
                title: "Descripción de fotografía o documentación",
              },
              {
                type: 6,
                required: true,
                value: null,
                name: "archivo",
                label: "Archivo adjunto",
                title: "Archivo Adjunto",
              },
            ],
          },
        ],
      });
      setCargando(false);
    }
  }, [dataUsr]);
  const afterSubmit = (body) => {
    return new Promise((resolve, reject) => {
      const jwt = JSON.parse(Cookies.get(process.env.REACT_APP_COOKIE_KEY));
      if (body.archives.length > 0) {
        let archivos_adjuntos = [];
        body.archives.map((actual, index) => {
          s3_service
            .saveFile(actual[1])
            .then((response_bucket) => {
              if (response_bucket != null) {
                archivos_adjuntos.push({
                  url: response_bucket.result.Location,
                  key: response_bucket.result.Key,
                  descripcion: actual[0],
                });
              }
              if (index == body.archives.length - 1) {
                admin_service
                  .postDataAuth(
                    "/inventario-ti",
                    jwt.TokenType,
                    jwt.accessToken,
                    {
                      descripcion: body.descripcion,
                      serial: body.serial,
                      vencimiento_garantia: body.vencimiento_garantia,
                    }
                  )
                  .then((response_database) => {
                    admin_service
                      .postDataAuth(
                        "/fotografias-inventario-ti",
                        jwt.TokenType,
                        jwt.accessToken,
                        {
                          fotografias: archivos_adjuntos,
                          id: response_database.data.response_database.id,
                        }
                      )
                      .then((response_db) => {
                        window.location.href = `/inventario-ti/view/${response_database.data.response_database.id}`;
                        resolve();
                      })
                      .catch((error) => {
                        console.log(error);
                        reject(
                          "ocurrido un error al guardar la información del equipo, intente de nuevo"
                        );
                      });
                  })
                  .catch((error) => {
                    console.log(error);
                    reject(
                      "ocurrido un error al guardar la información del equipo, intente de nuevo"
                    );
                  });
              }
            })
            .catch((error) => {
              console.log(error);
              reject(
                "ocurrido un error al guardar el archivo adjunto, intente de nuevo"
              );
            });
        });
      } else {
        admin_service
          .postDataAuth("/inventario-ti", jwt.TokenType, jwt.accessToken, {
            descripcion: body.descripcion,
            serial: body.serial,
            vencimiento_garantia: body.vencimiento_garantia,
          })
          .then((response_database) => {
            window.location.href = `/inventario-ti/view/${response_database.data.response_database.id}`;
            resolve();
          })
          .catch((error) => {
            console.log(error);
            reject(
              "ocurrido un error al guardar la información del equipo, intente de nuevo"
            );
          });
      }
    });
  };
  return (
    <React.Fragment>
      <CssBaseline />
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={cargando}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Box sx={{ width: "100%" }} paddingTop={5} paddingBottom={5}>
        <Stack spacing={5}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link underline="hover" color="inherit" href="/">
              Inicio
            </Link>
            <Link underline="hover" color="inherit" href={"/inventario-ti"}>
              Inventario IT
            </Link>

            <Typography color="text.primary">Registrar Equipo</Typography>
          </Breadcrumbs>
          <Formulario afterSubmit={afterSubmit} formConfig={formConfig} />
        </Stack>
      </Box>
    </React.Fragment>
  );
};

export default CreateInventarioTI;
